import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Button,
  Spinner,
  Image,
  Flex,
  CircularProgress,
  HStack,
  CircularProgressLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import Setting from "../assets/setting.svg";
import { evaluateOrganization, loadRubricEvaluation } from "../services/api";
import { shortenText } from "../services/common";

const SectionText = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <>
      <Text fontSize="12px">
        {isExpanded ? text : shortenText(text, maxLength)}
      </Text>
      {text.length > maxLength && (
        <Button
          size="xs"
          variant="link"
          onClick={toggleExpand}
          mt={1}
          color="#F68821"
        >
          {isExpanded ? "View Less" : "View More"}
        </Button>
      )}
    </>
  );
};

const getRating = (score) => {
  if (score >= 0 && score <= 7) return 'Poor';
    if (score >= 8 && score <= 14) return 'Fair';
    if (score >= 15 && score <= 21) return 'Average';
    if (score >= 22 && score <= 28) return 'Good';
    if (score >= 29 && score <= 35) return 'Excellent';
  return "Excellent";
};

const getScoreColor = (score) => {
  if (score >= 21 && score <= 35) return "green.500";
  if (score >= 0 && score <= 12) return "red.500";
  return "black";
};

const renderContent = (content) => {
  if (typeof content === "object" && content !== null) {
    const { "Overall Score": overallScore, Evaluation, Sections } = content;

    const displayScore = overallScore < 0 ? 5 : Math.min(overallScore, 35);
    const progressValue = (displayScore / 35) * 100;
    const rating = getRating(displayScore);
    // const ratingColor = getRatingColor(rating);
    const scoreColor = getScoreColor(displayScore);
    return (
      <Box
        bg="transparent"
        py={{ base: 0, lg: 5 }}
        borderRadius="lg"
        boxShadow="none"
        width="100%"
      >
        <Flex
          justifyContent="start"
          gap={"6px"}
          justifyItems={"flex-start"}
          align="center"
          display={"flex"}
          mb={4}
          flexDirection={"row-reverse"}
        >
          <Box>
            <Heading fontWeight={400} as="h2" size="14px" mb={1}>
              Overall Score
            </Heading>
            <Heading as="h3" fontWeight={500} size="14px">
              {rating}
            </Heading>
          </Box>
          <CircularProgress
            value={progressValue}
            color="#EB6F7E"
            size="110px"
            borderRadius={"12px"}
            thickness="8px"
          >
            <CircularProgressLabel justifyContent={"center"} display={"flex"} color={"gray.500"} fontSize="14px"  fontWeight="400">
            <Text color={scoreColor}>{displayScore}</Text>/35
            </CircularProgressLabel>
          </CircularProgress>
        </Flex>

        <Text fontSize="14px" mb={2}>
          {Evaluation}
        </Text>

        <VStack spacing={4} align="stretch">
          {Sections &&
            Sections.map((section, index) => (
              <HStack
                key={index}
                flexDirection={"column"}
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                spacing={0}
                borderBottom={"0.78px solid #FEE6D4"}
                align="center"
              >
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  mb={"2px"}
                  width={"100%"}
                  gap={"12px"}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    gap={"8px"}
                    flexDirection={"row"}
                    alignItems={"center"}
                  >
                    <Image src={Setting} alt="Setting Icons" mt={1} />
                    <Box flex={1}>
                      <Text fontWeight="500" fontSize="14px" color={"#F68821"}>
                        {shortenText(section["Section Name"], 30)}
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    bg="#F68821"
                    color="white"
                    px={2}
                    py={1}
                    fontSize={"12px"}
                    borderRadius="4px"
                    fontWeight="normal"
                  >
                    {section.Score}/7
                  </Box>
                </Flex>
                <SectionText text={section.Evaluation} maxLength={100} />
              </HStack>
            ))}
        </VStack>
      </Box>
    );
  }

  return null;
};

const EvaluateOrganization = ({ projectName, section, draftContent }) => {
  const [loading, setLoading] = useState(false);
  const [evaluationData, setEvaluationData] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const fetchEvaluationData = async () => {
    try {
      const response = await loadRubricEvaluation(projectName, section);
      console.log("Evaluation data:", response);
      setEvaluationData(response);
    } catch (error) {
      console.error(
        "Error fetching evaluation data:",
        error.response?.data || error.message
      );
      if (error.response && error.response.status === 500) {
        setIsErrorModalOpen(true);
      }
    }
  };

  useEffect(() => {
    fetchEvaluationData();
  }, [section, draftContent]);
  const handleEvaluateOrganization = async () => {
    let message = `Evaluate the ${section} section based on the provided draft.`;
    try {
      setLoading(true);
      const response = await evaluateOrganization(
        projectName,
        section,
        message
      );
      setEvaluationData(response.response);
      fetchEvaluationData();
    } catch (error) {
      console.error(
        "Error evaluating organization section:",
        error.response?.data || error.message
      );
      if (error.response && error.response.status === 500) {
        setIsErrorModalOpen(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };


  return (
    <>
    <Box
      px={{ base: 8, lg: 4 }}
      className="hideScrollbar"
      width={"100%"}
      backgroundColor={"#FEF8F5"}
      py={2}
      borderRadius={"8px"}
    >
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <VStack
          align="start"
          spacing={2}
          className="hideScrollbar"
          // maxHeight={{ base: "200px", lg: "500px" }}
          overflowY={"scroll"}
        >
          {evaluationData && renderContent(evaluationData)}

          <Box mt={0}>
            <Button
              onClick={handleEvaluateOrganization}
              colorScheme="red"
              mt={2}
              isDisabled={!draftContent}
              isLoading={loading}
            >
              {loading
                ? "Evaluating... "
                : evaluationData
                ? "Evaluate Again"
                : "Evaluate"}
            </Button>
          </Box>
        </VStack>
      )}
    </Box>

    {/* <Modal isOpen={isErrorModalOpen} onClose={closeErrorModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Error</ModalHeader>
        <ModalBody>
          <Text>Server is busy, please try again later.</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={closeErrorModal}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal> */}
  </>
  );
};

export default EvaluateOrganization;
