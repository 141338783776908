import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Link,
  VStack,
  HStack,
  Input,
  Image,
  Flex,
  IconButton,
  Collapse,
} from "@chakra-ui/react";
import {
  AddIcon,
  MinusIcon,
  ChevronDownIcon,
  ArrowForwardIcon,
} from "@chakra-ui/icons";
import axios from "axios";
import home_icon from "../assets/home_icon.png";
import start_new from "../assets/start_new.png";
// import { API_URL } from "../services/api";
import send_inactive_icon from "../assets/search_button.png";
import send_active_icon from "../assets/send_active_icon.png";
import BreadCrumb from "./BreadCrumb/BreadCrumb";
import useApiClient from "../services/useApiClient";

const SidebarItem = ({ icon, label, children, isOpen, onToggle }) => (
  <Box w="full">
    <HStack p={4} cursor="pointer" onClick={onToggle} justify="space-between">
      <HStack spacing={3}>
        {icon}
        <Text color={isOpen ? "#FF7A59" : "#333333"}>{label}</Text>
      </HStack>
      <IconButton
        icon={
          isOpen ? (
            <MinusIcon color={"#C01F27"} />
          ) : (
            <AddIcon color={"#C01F27"} />
          )
        }
        size="sm"
        variant={""}
        aria-label={isOpen ? "Collapse" : "Expand"}
      />
    </HStack>
    <Collapse in={isOpen}>
      <VStack align="start" pl={4} bg="#FEF8F5" spacing={2} py={2}>
        {children}
      </VStack>
    </Collapse>
  </Box>
);

const SalesDraft = () => {
  const { api, conversation_api } = useApiClient();
  const [companyName, setCompanyName] = useState("");
  const [products, setProducts] = useState("");
  const [business, setBusiness] = useState("");
  const [painPoints, setPainPoints] = useState("");
  const [keyBenefits, setKeyBenefits] = useState("");
  const [commonObjections, setCommonObjections] = useState("");
  const [callToAction, setCallToAction] = useState("");
  const [draft, setDraft] = useState("");
  const [loading, setLoading] = useState(false);
  const [salesList, setSalesList] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    fetchSalesDrafts();
  }, []);

  const fetchSalesDrafts = async () => {
    try {
      const response = await api.get(`/sales/sales_script/list`);
      // console.log("response: ",response)
      setSalesList(response.data);
    } catch (error) {
      console.error("Error fetching sales drafts:", error);
    }
  };

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleGenerateDraft = async () => {
    if (
      !companyName ||
      !products ||
      !business ||
      !painPoints ||
      !keyBenefits ||
      !commonObjections ||
      !callToAction
    ) {
      alert("All fields are required!");
      return;
    }
    setLoading(true);
    try {
      const response = await api.post(`/sales/sales_script`, {
        company_name: companyName,
        products,
        business,
        pain_points: painPoints,
        key_benefits: keyBenefits,
        common_objections: commonObjections,
        call_to_action: callToAction,
      });
      const newSalesDraft = {
        content: response.data.sales_content,
        timestamp: new Date().toISOString(),
        name: companyName,
      };
      setDraft(newSalesDraft.content);
      //   console.log("newSalesDraft: ", newSalesDraft);
      // setSalesList([newSalesDraft, ...salesList]);
      fetchSalesDrafts();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleNewSalesChat = () => {
    setCompanyName("");
    setProducts("");
    setBusiness("");
    setPainPoints("");
    setKeyBenefits("");
    setCommonObjections("");
    setCallToAction("");
    setDraft("");
  };

  const handleSales = (sales) => {
    setDraft(sales.salescript.sales_content);
  };

  const groupSalesByDate = (sales) => {
    const groupedSales = {
      today: [],
      yesterday: [],
      thisWeek: [],
      thisMonth: [],
    };

    const today = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const oneWeek = 7 * oneDay;
    const startOfToday = new Date(today.setHours(0, 0, 0, 0));
    const startOfYesterday = new Date(startOfToday.getTime() - oneDay);
    const startOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay()
    );
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    if (sales.length === 0) return groupedSales;

    sales.forEach((sale) => {
      const salesDate = new Date(sale.created_at); // Convert created_at to Date object
      if (salesDate >= startOfToday) {
        groupedSales.today.push(sale);
      } else if (salesDate >= startOfYesterday) {
        groupedSales.yesterday.push(sale);
      } else if (salesDate >= startOfWeek) {
        groupedSales.thisWeek.push(sale);
      } else if (salesDate >= startOfMonth) {
        groupedSales.thisMonth.push(sale);
      }
    });

    return groupedSales;
  };

  const groupedSales = groupSalesByDate(salesList);
  const breadcrumbPaths = [
    { label: "Home", href: "/" },
    { label: "AI Assistants", href: "/assistants" },
    { label: "Sales", href: "/agent-container" },
    { label: "Create a Sales Script", href: "/sales/sales-draft" },
  ];
  return (
    <Box bg={"#FFFFFF"} minH={"100vh"}>
      {/* Navabr  */}

      <Flex bg={"#FFFFFF"} minH={"95vh"}>
        {/* Sidebar */}
       
        <Flex
          left={{ base: 5, sm: 20 }}
          py={{ base: "15px", sm: "0px" }}
          display={"flex"}
          position={"absolute"}
          alignItems={"center"}
          flexDirection={"row"}
          gap={"8px"}
        >
          <BreadCrumb paths={breadcrumbPaths} />
        </Flex>
        <Box
          bg="#ffffff"
          color="#000000"
          p={8}
          display={"flex"}
          flexDirection={"column"}
          alignSelf={"flex-start"}
          justifyContent={"flex-end"}
          maxW="1000px"
          mx="auto"
        >
          <Box my={8} textAlign={"left"}>
            <Heading as="h2" size="lg" fontWeight={400}>
              Create Sales Script
            </Heading>
          </Box>

          {draft && (
            <Box mt={8}>
              <Heading as="h4" size="md" mb={4}>
                Generated Sales Script:
              </Heading>
              <Text whiteSpace="pre-wrap">{draft}</Text>
            </Box>
          )}

          {!draft && (
            <>
              <Box
                mb={4}
                letterSpacing={2}
                border={"1px solid #FEE6D4"}
                p={4}
                borderRadius={"8px"}
              >
                <Text
                  lineHeight={"50px"}
                  mb={{ base: "8px", sm: "0px" }}
                  position={"relative"}
                >
                  I am from{" "}
                  <Input
                    placeholder="Your Company name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    display="inline-block"
                    style={{
                      backgroundColor: "#FEF8F5",
                      borderBottom: "1px dotted #E66E50",
                      borderTopRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                    }}
                    border={"none"}
                    width="auto"
                    mx={0}
                    color="black"
                    bg="white"
                  />
                  &nbsp;, selling{" "}
                  <Input
                    placeholder="Products/Services"
                    value={products}
                    onChange={(e) => setProducts(e.target.value)}
                    display="inline-block"
                    width="auto"
                    style={{
                      backgroundColor: "#FEF8F5",
                      borderBottom: "1px dotted #E66E50",
                      borderTopRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                    }}
                    mx={2}
                    color="black"
                    bg="white"
                  />
                  , to customers in the{" "}
                  <Input
                    placeholder="Customer's Industry/Business"
                    value={business}
                    onChange={(e) => setBusiness(e.target.value)}
                    display="inline-block"
                    width="auto"
                    style={{
                      backgroundColor: "#FEF8F5",
                      borderBottom: "1px dotted #E66E50",
                      borderTopRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                    }}
                    border={"none"}
                    mx={2}
                    color="black"
                    bg="white"
                  />
                  sector, addressing their pain points of{" "}
                  <Input
                    placeholder="Customer's Pain Points"
                    value={painPoints}
                    onChange={(e) => setPainPoints(e.target.value)}
                    display="inline-block"
                    width="auto"
                    style={{
                      backgroundColor: "#FEF8F5",
                      borderBottom: "1px dotted #E66E50",
                      borderTopRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                    }}
                    border={"none"}
                    mx={2}
                    color="black"
                    bg="white"
                  />
                  , highlighting key benefits like{" "}
                  <Input
                    placeholder="Key Benefits"
                    value={keyBenefits}
                    onChange={(e) => setKeyBenefits(e.target.value)}
                    display="inline-block"
                    width="auto"
                    style={{
                      backgroundColor: "#FEF8F5",
                      borderBottom: "1px dotted #E66E50",
                      borderTopRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                    }}
                    border={"none"}
                    mx={2}
                    color="black"
                    bg="white"
                  />
                  , overcoming objections such as{" "}
                  <Input
                    placeholder="Common Objections"
                    value={commonObjections}
                    onChange={(e) => setCommonObjections(e.target.value)}
                    display="inline-block"
                    width="auto"
                    style={{
                      backgroundColor: "#FEF8F5",
                      borderBottom: "1px dotted #E66E50",
                      borderTopRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                    }}
                    border={"none"}
                    mx={2}
                    color="black"
                    bg="white"
                  />
                  , and concluding with a call to action to{" "}
                  <Input
                    placeholder="Call to Action"
                    value={callToAction}
                    onChange={(e) => setCallToAction(e.target.value)}
                    display="inline-block"
                    width="auto"
                    style={{
                      backgroundColor: "#FEF8F5",
                      borderBottom: "1px dotted #E66E50",
                      borderTopRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                    }}
                    border={"none"}
                    mx={2}
                    color="black"
                    bg="white"
                  />
                  .
                  {/* <Button
            onClick={handleGenerateDraft}
            mt={4}
            colorScheme="red"
            position={"absolute"}
            right={"0"}
            isLoading={loading}
          >
            <ArrowForwardIcon  />
          </Button> */}
                  <Image
                    w={{ base: "30px", md: "30px" }}
                    h={{ base: "30px", md: "30px" }}
                    mr={3}
                    position={"absolute"}
                    right={"0"}
                    bottom={"1"}
                    src={send_active_icon}
                    alt="search_button"
                    cursor={"pointer"}
                    onClick={handleGenerateDraft}
                  />
                </Text>
              </Box>
            </>
          )}

          {/* {draft &&  <Box my={8} pos="relative">
          <Flex
            border="1px solid #D9D9D9"
            borderRadius="16px"
            h={{ base: "60px", md: "70px" }}
            align="center"
            justifyContent="center"
          >
            <Input
              placeholder="Type your response here..."
              size="lg"
              bg="white"
              pr="4.5rem"
              border="none"
              value={inputValue}
              h={{ base: "50px", md: "60px" }}
              onChange={(e) => setInputValue(e.target.value)}
              // onKeyDown={(e) => e.key === "Enter" && sendMessage()}
              focusBorderColor="transparent"
              _focus={{ boxShadow: "none" }}
            />
            <Image
              w={{ base: "30px", md: "30px" }}
              h={{ base: "30px", md: "30px" }}
              mr={3}
              src={inputValue ? send_active_icon : send_inactive_icon}
              alt="search_button"
              _disabled={"true"}
              onClick={handleGenerateDraft}
            />
          </Flex>
        </Box>} */}
        </Box>
      </Flex>
    </Box>
  );
};

export default SalesDraft;
