import React, { useEffect, useState } from "react";
import {
  Box,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  Button,
  Flex,
  Image,
  Icon,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon, EditIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import leftarrow from "../assets/sideNavBar.svg";
import wadhwani from "../assets/wadhwaniLogoBig.svg";
import Edit from "../assets/edit.svg";
import rArrow from "../assets/rightArrowwithBg.svg";
import time from '../assets/time.svg';
import { setProjectName as setProjectNameRedux, setSectionName } from "../actions/projectActions";
import useApiClient from "../services/useApiClient";

const SideNavigation = () => {
  const [projects, setProjects] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [groupedProjects, setGroupedProjects] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { api } = useApiClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const { data } = await api.get(`/all_projects/list_projects`);
      setProjects(data);
      groupProjectsBySection(data);
    } catch (error) {
      console.error("Error fetching projects:", error);
      // Fallback to example data if API fails
      const exampleData =  [
        {
          _id: "1",
          category: "Playbook",
          section: "Sales",
          created_at: Date.now(),
        },
        {
          _id: "2",
          category: "Idea rators",
          section: "Marketing",
          created_at: Date.now() - 86400000,
        },
        {
          _id: "3",
          category: "Create Sales",
          section: "HR",
          created_at: Date.now() - 172800000,
        },
        {
          _id: "4",
          category: "Strategy",
          section: "Strategy",
          created_at: Date.now() - 259200000,
        },
        {
          _id: "5",
          category: "Business Plan",
          section: "Marketing",
          created_at: Date.now() - 345600000,
        },
        {
          _id: "6",
          category: "Business Plan",
          section: "Marketing",
          created_at: Date.now() - 432000000,
        },
      ];
      setProjects(exampleData);
      groupProjectsBySection(exampleData);
    }
  };

  const groupProjectsBySection = (projects) => {
    const grouped = projects.reduce((acc, project) => {
      if (!acc[project.section]) {
        acc[project.section] = [];
      }
      acc[project.section].push(project);
      return acc;
    }, {});

    // Group projects by time within each section
    Object.keys(grouped).forEach((section) => {
      grouped[section] = groupProjectsByTime(grouped[section]);
    });

    setGroupedProjects(grouped);
  };

  const groupProjectsByTime = (projects) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const thisWeekStart = new Date(today);
    thisWeekStart.setDate(thisWeekStart.getDate() - thisWeekStart.getDay());
    const thisMonthStart = new Date(now.getFullYear(), now.getMonth(), 1);

    return projects.reduce((acc, project) => {
      const createdAt = new Date(project.created_at);
      if (createdAt >= today) {
        acc.today.push(project);
      } else if (createdAt >= yesterday) {
        acc.yesterday.push(project);
      } else if (createdAt >= thisWeekStart) {
        acc.thisWeek.push(project);
      } else if (createdAt >= thisMonthStart) {
        acc.thisMonth.push(project);
      } else {
        acc.older.push(project);
      }
      return acc;
    }, { today: [], yesterday: [], thisWeek: [], thisMonth: [], older: [] });
  };

  const handleContinue = (project) => {
    console.log("Project: ", project);

    if (
      project.section === "marketing" &&
      project.category === "businessplan"
    ) {
      dispatch(setProjectNameRedux(project.projectname));
      localStorage.setItem("projectName", project.projectname);
      localStorage.setItem("sectionName", "company_description");
      navigate(`/business-plan/company_description`);
      return;
    }
    dispatch(setSectionName(project.category));
    localStorage.setItem("projectName", project.section);
    localStorage.setItem("sectionName", project.category);
    setModalOpen(false);

    if (project.chat_id) {
      navigate(
        `${project.section.replace(" ", "-")}/${project.category.replace(
          " ",
          "-"
        )}/${project.chat_id}`
      );
    } else {
      navigate(`${project.section.replace(" ", "-")}/${project.category.replace(" ", "-")}`);
    }
    onClose();
  };

  const handleStartNew = (project) => {
    console.log("Project: ", project);
    if (project.section === "marketing" && project.category === "businessplan") {
      navigate("/")
    }
    else {
      navigate(`${project.section.replace(" ", "-")}/${project.category.replace(" ", "-")}`);
    }
    onClose();
  }

  const renderTimeGroup = (projects, timeLabel) => {
    if (projects.length === 0) return null;
    return (
      <Box key={timeLabel}>
        <Text fontSize="sm" fontWeight="bold" color={"#EB6F7E"} mb={2}>{timeLabel}</Text>
        {projects.map((project, index) => (
          <Box
            key={index}
            cursor="pointer"
            position="relative"
            mb={2}
            _hover={{
              "& > .edit-icon": { opacity: 1 },
              color: "#F68821",
            }}
          >
            <Text
              fontSize="14px"
              lineHeight="18px"
              color="#333333"
              onClick={() => handleContinue(project)}
              _hover={{ color: "#F68821" }}
              fontWeight={400}
              textTransform="capitalize"
            >
              {project.section === "marketing" && project.category === "businessplan"
                ? project.projectname
                : project.question}
            </Text>
            <Text
              fontSize="10px"
              lineHeight="15px"
              color="#666666"
              _hover={{ color: "#F68821" }}
              my={1}
              fontWeight={400}
            >
              ({project.category})
            </Text>
            <Icon
              as={EditIcon}
              className="edit-icon"
              position="absolute"
              right="-5px"
              top="73%"
              transform="translateY(-50%)"
              opacity={0}
              transition="opacity 0.2s"
              color="#F68821"
              onClick={() => handleStartNew(project)}
            />
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <>
      <Button
        onClick={onOpen}
        _hover={{ backgroundColor: "transparent" }}
        left={-4}
        backgroundColor="transparent"
        boxShadow="none"
        // zIndex={1040}
        zIndex={2000}
      >
        <Image src={leftarrow} alt="Open sidebar"    zIndex={2000} />
      </Button>
      <Drawer  backgroundColor={"transparent"} placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent py={1} backgroundColor="transparent" borderRadius="12px">
          <Box
            bg="#FEF8F5"
            border="1px solid #C01F27"
            borderRadius="12px"
            w={{ base: "250px", lg: "320px" }}
            overflow="auto"
            className="hideScrollbar"
            h="100vh"
            p={{ base: 1, lg: 1 }}
          >
            <Button
              onClick={onClose}
              top={{ base: "80%", lg: "50%" }}
              position="absolute"
              right={{ base: 8, lg: -10 }}
              backgroundColor="transparent"
              _hover={{ backgroundColor: "transparent" }}
            >
              <Image src={rArrow} alt="Close sidebar" />
            </Button>
            <VStack align="stretch" spacing={4}>
              <Image
                 src={wadhwani}
                alt="Wadhwani Foundation"
                w="140px"
                ml="10px"
              />
              <Accordion allowMultiple>
                <AccordionItem border="none">
                  {({ isExpanded }) => (
                    <>
                      <h2>
                        <AccordionButton
                          as="span"
                          _hover={{ bg: "transparent" }}
                          _focus={{ boxShadow: "none" }}
                        >
                          <Box
                            flex="1"
                            display="flex"
                            fontSize={{ base: "14px", lg: "16px" }}
                            alignItems="center"
                            gap="8px"
                            textAlign="left"
                            border="none"
                            fontWeight="regular"
                            color="#333333"
                          >
                            <Image src={time} alt="Time icon" />
                            Conversation History
                          </Box>
                          {isExpanded ? (
                            <MinusIcon fontSize="12px" color="#C01F27" />
                          ) : (
                            <AddIcon fontSize="12px" color="#C01F27" />
                          )}
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pl="19px">
                        <Accordion allowMultiple overflowY="scroll" className="hideScrollbar">
                          {Object.entries(groupedProjects).map(([section, timeGroups]) => (
                            <AccordionItem key={section} border="none">
                              <h2>
                                <AccordionButton
                                  px={0}
                                  _hover={{ bg: "transparent" }}
                                  _focus={{ boxShadow: "none" }}
                                  cursor="pointer"
                                >
                                  <Box
                                    flex="1"
                                    display="flex"
                                    fontSize={{ base: "14px", lg: "16px" }}
                                    alignItems="center"
                                    gap="10px"
                                    textAlign="left"
                                    border="none"
                                    fontWeight="regular"
                                    color="#333333"
                                    textTransform="capitalize"
                                  >
                                    {section}
                                  </Box>
                                  <AccordionIcon color="#C01F27" />
                                </AccordionButton>
                              </h2>
                              <AccordionPanel
                                height="300px"
                                overflowY="auto"
                                className="hideScrollbar"
                                ml="0"
                                borderLeft="2px solid #F68821"
                              >
                                {renderTimeGroup(timeGroups.today, "Today")}
                                {renderTimeGroup(timeGroups.yesterday, "Yesterday")}
                                {renderTimeGroup(timeGroups.thisWeek, "This Week")}
                                {renderTimeGroup(timeGroups.thisMonth, "This Month")}
                                {renderTimeGroup(timeGroups.older, "Older")}
                              </AccordionPanel>
                            </AccordionItem>
                          ))}
                        </Accordion>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            </VStack>
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SideNavigation;
