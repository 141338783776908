import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  VStack,
  Text,
  Spinner,
  Heading,
  Flex,
  Avatar,
  Image,
  HStack,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
// import {
//   PDFDownloadLink,
//   Page,
//   Text as PDFText,
//   View,
//   Document,
//   StyleSheet,
// } from "@react-pdf/renderer";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
// import PDFDocument from "./PDFDocument";
import generatePDF from "./PDFDocument";
import Draft from "./DraftSection";
import {
  chatWithProject,
  createDraft,
  loadHistory,
  loadDraft,
  loadAllDrafts,
} from "../services/api";

import send_inactive_icon from "../assets/search_button.png";
import send_active_icon from "../assets/send_active_icon.png";
import genie_logo from "../assets/genie_logo.png";
import Rec_icon from "../assets/Rec_icon.png";
import draft from "../assets/draft.png";
import build from "../assets/build.png";
import ImproveSection from "./ImproveSection";
import FormatMessageContent from "./FormatMessage/FormatMessage";

const sections = {
  // summary: "Summary",
  // executive_summary: "Executive Summary",
  company_description: "Company Description",
  market_analysis: "Market Analysis",
  organization: "Organization",
  products_and_services: "Products and Services",
  marketing_and_sales: "Marketing and Sales",
  financial_projections: "Financial Projections",
  funding_requirements: "Funding Requirements",
};

// const styles = StyleSheet.create({
//   page: {
//     padding: 20,
//   },
//   section: {
//     marginBottom: 10,
//   },
//   heading: {
//     fontSize: 18,
//     marginBottom: 10,
//   },
//   text: {
//     fontSize: 12,
//     marginBottom: 5,
//   },
//   strong: {
//     fontSize: 12,
//     fontWeight: "bold",
//   },
// });

// const GenerateCompletePDFDocument = ({ content }) => (
//   <Document>
//     <Page style={styles.page}>
//       {content.map((section) => (
//         <View key={section.section_name} style={styles.section}>
//           <PDFText style={styles.title}>{section.draft[Object.keys(section.draft)[0]].Title}</PDFText>
//           {Object.entries(section.draft[Object.keys(section.draft)[0]]).map(([key, value]) => {
//             if (key === "Title") return null; // Skip the title as we've already rendered it
//             return (
//               <View key={key}>
//                 <PDFText style={styles.heading}>{key}</PDFText>
//                 {typeof value === "object" ? (
//                   <>
//                     <PDFText style={styles.text}>{value.Description}</PDFText>
//                     {value.KeyPoints && (
//                       <View>
//                         {value.KeyPoints.map((point, index) => (
//                           <PDFText key={index} style={styles.listItem}>• {point}</PDFText>
//                         ))}
//                       </View>
//                     )}
//                   </>
//                 ) : (
//                   <PDFText style={styles.text}>{value}</PDFText>
//                 )}
//               </View>
//             );
//           })}
//         </View>
//       ))}
//     </Page>
//   </Document>
// );

const PlanSection = ({ section }) => {
  const [userInput, setUserInput] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [isDraftReady, setIsDraftReady] = useState(false);
  const [draftContent, setDraftContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [mergedDraft, setMergedDraft] = useState(null);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0); // State to manage active tab index
  const projectName = useSelector((state) => state.project.projectName);
  const chatContainerRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const toast = useToast();
  // const messagesEndRef = useRef(null);
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatHistory]);

  const handleDownloadCompletePlan = useCallback(async () => {
    setIsGeneratingPDF(true);
    try {
      const drafts = await loadAllDrafts(projectName);
      console.log("Drafts data:", JSON.stringify(drafts, null, 2));
      // Generate PDF
      const doc = generatePDF(drafts);

      // Save the PDF
      doc.save("business_draft.pdf");

      toast({
        title: "PDF Generated Successfully",
        description: "Your business plan draft PDF has been downloaded.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error generating complete plan:", error);
      toast({
        title: "PDF Generation Failed",
        description:
          "There was an error generating your PDF. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsGeneratingPDF(false);
    }
  }, [projectName, toast]);

  // const handleDownloadCompletePlan = useCallback(async () => {
  //   setIsGeneratingPDF(true);
  //   try {
  //     const drafts = await loadAllDrafts(projectName);

  //     // Generate and download PDF
  //     const blob = await pdf(<PDFDocument content={drafts} />).toBlob();
  //     saveAs(blob, 'business_plan.pdf');

  //     toast({
  //       title: "PDF Generated Successfully",
  //       description: "Your business plan PDF has been downloaded.",
  //       status: "success",
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //   } catch (error) {
  //     console.error("Error generating complete plan:", error);
  //     toast({
  //       title: "PDF Generation Failed",
  //       description: "There was an error generating your PDF. Please try again.",
  //       status: "error",
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //   } finally {
  //     setIsGeneratingPDF(false);
  //   }
  // }, [projectName, toast]);

  const fetchChatHistory = useCallback(async () => {
    setLoadingHistory(true);
    try {
      const response = await loadHistory(projectName, section);
      setChatHistory(response.history);
    } catch (error) {
      console.error(
        "Error fetching chat history:",
        error.response?.data || error.message
      );
    }
    setLoadingHistory(false);
  }, [projectName, section]);

  const fetchAllDrafts = async (projectName) => {
    const drafts = {};
    try {
      const response = await loadAllDrafts(projectName);
      console.log("All drafts response:", response);
      return response;
      // if (response.draft) {
      //   drafts[sectionKey] = response.draft;
      // } else {
      //   drafts[sectionKey] = ""; // Handle missing drafts
      // }
    } catch (error) {
      // drafts[sectionKey] = ""; // Handle errors
      console.error(`Error fetching drafts for ${projectName}:`, error);
    }
    return drafts;
  };

  const mergeDrafts = (drafts) => {
    const mergedContent = {};
    for (const sectionKey of Object.keys(sections)) {
      if (drafts[sectionKey]) {
        mergedContent[sectionKey] = drafts[sectionKey];
      }
    }
    return mergedContent;
  };

  const fetchDraft = useCallback(async () => {
    setLoadingDraft(true);
    try {
      const response = await loadDraft(projectName, section);
      console.log("Draft response:", response);
      if (response) {
        setDraftContent(response);
      }
    } catch (error) {
      setDraftContent("");
      console.error(
        "Error fetching draft:",
        error.response?.data || error.message
      );
    }
    setLoadingDraft(false);
  }, [projectName, section]);

  useEffect(() => {
    if (projectName && section) {
      // setActiveTabIndex(0); // Reset to "Input" tab when section changes
      setIsDraftReady(false);
      fetchChatHistory();
      fetchDraft();
      scrollToBottom();
    }
  }, [projectName, section]);

  useEffect(() => {
    if (chatHistory.length) {
      scrollToBottom();
    }
  }, [chatHistory]);


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSendMessage = async (message) => {
    if (!message) return;

    const updatedConversation = [
      ...chatHistory,
      { role: "user", content: message },
    ];

    setUserInput("");

    try {
      setLoading(true);
      setIsResponseLoading(true);
      setChatHistory(updatedConversation);
      const response = await chatWithProject(projectName, section, message);

      setChatHistory([
        ...updatedConversation,
        { role: "assistant", content: response.response },
      ]);
      setIsResponseLoading(false);
      if (
        response.response.toLowerCase().includes("drafting") ||
        response.response.toLowerCase().includes("create a draft") ||
        response.response.toLowerCase().includes("create draft") ||
        response.response.toLowerCase().includes("draft based on") ||
        response.response.toLowerCase().includes("creating a draft") ||
        response.response.toLowerCase().includes("developing the draft") ||
        response.response.toLowerCase().includes("shall i draft") ||
        response.response.toLowerCase().includes("click on continue") ||
        response.response.toLowerCase().includes("button") ||
        response.response.toLowerCase().includes("ready to create a draft")
      ) {
        setIsDraftReady(true);
        setIsResponseLoading(false);
      }
    } catch (error) {
      console.error(
        "Error sending message:",
        error.response?.data || error.message
      );
      setChatHistory([
        ...updatedConversation,
        { role: "assistant", content: "Error sending message" },
      ]);
    }
    setIsResponseLoading(true);
    setLoading(false);
  };

  const handleUserInput = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      handleSendMessage(userInput);
    } else if (e.key === "Enter") {
      e.preventDefault();
      setUserInput((prev) => prev + "\n");
    }
  };

  const handleCreateDraft = async () => {
    setActiveTabIndex(1); // Switch to the Draft tab
    setIsDraftReady(false);
  };

  return (
    <Box
      flex="1"
      px={4}
      bg={"white"}
      pl={{ base: "0rem", lg: "5rem" }}
      maxW={{ base: "100%", lg: "65rem" }}
      w={"100%"}
      mx={"auto"}
    >
      <Tabs
        variant="soft-rounded"
        colorScheme="orange"
        index={activeTabIndex}
        onChange={(index) => setActiveTabIndex(index)}
      >
        <Flex alignItems={"center"} justifyContent={"flex-start"}>
          {/* <Heading size="md" mr={8}>
            {sections[section]}
          </Heading> */}
          <TabList
            bg={"transparent"}
            // width={"-webkit-fit-content"}
            borderRadius="4px"
            w={"100%"}
            pl={{ base: "12px", lg: "" }}
            className="hideScrollbar"
            gap={4}
            py={{ base: "12px", lg: "0px" }}
            overflowX={"scroll"}
            color={"#333333"}
          >
            <Tab
              _selected={{ color: "#C01F27", bg: "#FEF8F5" }}
              borderRadius="8px"
              py={2}
              px={4}
            >
              <HStack justifyContent={"center"}>
                <Image src={build} alt="Start New" boxSize="20px" />
                <Text>Build</Text>
              </HStack>
            </Tab>
            {section === "summary" ? (
              ""
            ) : (
              <Tab
                _selected={{ color: "#C01F27", bg: "#FEF8F5" }}
                borderRadius="0 0 0 0"
                // w={"107px"}
                py={2}
                px={4}
                isDisabled={draftContent ? false : isDraftReady ? false : true}
              >
                <HStack justifyContent={"center"}>
                  <Image src={draft} alt="Start New" boxSize="20px" />
                  <Text>Draft</Text>
                </HStack>
              </Tab>
            )}
            {section === "summary" ? (
              ""
            ) : (
              <Tab
                _selected={{ color: "#C01F27", bg: "#FEF8F5" }}
                borderRadius="0 4px 4px 0"
                // w={"107px"}
                py={2}
                px={4}
                isDisabled={!draftContent}
              >
                <HStack justifyContent={"center"}>
                  <Image src={Rec_icon} alt="Start New" boxSize="20px" />
                  <Text>Recommendation</Text>
                </HStack>
              </Tab>
            )}
          </TabList>

          <Flex justify="space-between" ml={3}>
            {/* {!mergedDraft && (
              <Button onClick={handleDownloadCompletePlan} colorScheme="red">
                Generate Business Plan
              </Button>
            )} */}
            <Button
              colorScheme="red"
              ml={3}
              variant="outline"
              onClick={handleDownloadCompletePlan}
              isLoading={isGeneratingPDF}
              isDisabled={!draftContent}
              loadingText="Generating PDF..."
              _hover={{ bg: "#BF2026", color: "white" }}
            >
              Download Complete Draft
            </Button>

            {/* <Button
              colorScheme="red"
              ml={3}
              variant="outline"
              onClick={handleDownloadCompletePlan}
              isLoading={isGeneratingPDF}
              loadingText="Generating PDF..."
              _hover={{ bg: "#BF2026", color: "white" }}
            >
              Download Complete Plan
            </Button> */}
            {/* {console.log("Merged Draft:", mergedDraft)} */}
            {/* {mergedDraft && (
              <PDFDownloadLink
                document={<GenerateCompletePDFDocument content={mergedDraft} />}
                fileName="complete_business_plan.pdf"
                style={{
                  textDecoration: "none",
                }}
              >
                {({ loading }) => (
                  <Button
                    colorScheme="red"
                    ml={3}
                    isLoading={loading}
                    loadingText="Preparing PDF..."
                  >
                    Download PDF
                  </Button>
                )}
              </PDFDownloadLink>
            )} */}

            {/* {mergedDraft && (
              <PDFDownloadLink
                document={<GenerateCompletePDFDocument content={mergedDraft} />}
                fileName="complete_plan.pdf"
                style={{
                  textDecoration: "none",
                }}
              >
                {({ loading }) =>
                  loading ? (
                    <Button colorScheme="red" isLoading>
                      Generating PDF ...
                    </Button>
                  ) : (
                    <Button colorScheme="red">Click to Download Plan</Button>
                  )
                }
              </PDFDownloadLink>
            )} */}
          </Flex>
        </Flex>
        <Flex mt={4}>
          <TabPanels>
            <TabPanel p={0} mt={4}>
              <VStack
                spacing={4}
                align="start"
                width="100%"
                className="hideScrollbar"
                maxHeight="calc(100% - 100px)"
                overflowY="auto"
              >
                <Box
                  ref={chatContainerRef}
                  flex="1"
                  width="100%"
                  overflowY="auto"
                  bg={{ base: "transparent", lg: "white" }}
                  p={4}
                  pb={"100px"}
                  className="hideScrollbar"
                >
                  {chatHistory.length > 0 ? (
                    chatHistory.map((msg, index) => (
                      <Flex key={index} align="flex-start" mb={4}>
                        {msg.role === "user" ? (
                          <Avatar
                            h="20px"
                            fontSize={"15px"}
                            w="20px"
                            name="User"
                            bg="#FEE6D4"
                            color="#333"
                            mr={3}
                          />
                        ) : (
                          <Avatar h="24px" w="24px" src={genie_logo} mr={3} />
                        )}
                        <Flex direction={"column"} gap={"0"}>
                          <Text
                            color={msg.role !== "user" ? "#F68821" : "#C01F027"}
                            fontSize={"12px"}
                          >
                            {msg.role !== "user" ? "Wadhwani GenieAI" : "You"}
                          </Text>
                          <Box
                            bg={
                              msg.role === "user"
                                ? "transparent"
                                : "transparent"
                            }
                            px={msg.role === "user" ? 0 : 0}
                            py={msg.role === "user" ? 0 : 0}
                            borderRadius="md"
                            width="100%"
                            fontSize={"14px"}
                          >
                            {FormatMessageContent(msg.content)}
                          </Box>
                        </Flex>
                        <div ref={messagesEndRef} />
                      </Flex>
                    ))
                  ) : (
                    <Box
                      display="flex"
                      alignItems="flex-end"
                      h="-webkit-fill-available"
                      justifyContent="center"
                    >
                      <Box
                        border="1px solid #FEE6D4"
                        borderRadius="md"
                        p={4}
                        bg="white"
                        onClick={() => handleSendMessage("Let's begin")}
                        cursor="pointer"
                        _hover={{ bg: "#FEE6D4" }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Text
                          w={"fit-content"}
                          color="#333333"
                          alignSelf="center"
                          justifyContent={"center"}
                        >
                          Let's begin
                        </Text>
                      </Box>
                    </Box>
                  )}
                  {loading && (
                    <Flex align="flex-start" mb={4}>
                      <Avatar h="24px" w="24px" src={genie_logo} mr={3} />
                      <Flex direction="column" gap="0px">
                        <Text color="#F68821" fontSize="12px">
                          Wadhwani GenieAI
                        </Text>
                        <Box
                          bg="transparent"
                          borderRadius="md"
                          width="100%"
                          fontSize="14px"
                        >
                          <Spinner size="sm" color="#F68821" />
                        </Box>
                      </Flex>
                    </Flex>
                  )}
                  {isDraftReady && (
                  <Button
                    colorScheme="orange"
                    onClick={handleCreateDraft}
                    isLoading={loading}
                    color={"white"}
                    backgroundColor={"#e53e3e"}
                  >
                    {loading ? "Creating Draft..." : "Continue"}
                  </Button>
                )}
                </Box>
                <Box
                  mb={0} // margin-bottom: 0
                  pos="fixed" // position: fixed
                  bottom={0} // bottom: 0
                  background="white" // background: white
                  paddingBottom="2em" // padding-bottom: 2em
                  width={{ base: "100%", sm: "100%" }}
                  maxWidth={{ base: "100%", lg: "60rem" }}
                >
                  <Flex
                    border={"1px solid #D9D9D9"}
                    borderRadius={"16px"}
                    h={"70px"}
                    boxShadow={"sm"}
                    align={"center"}
                    width={"100%"}
                    justifyContent={"center"}
                    _focusWithin={{
                      border: "2px solid #E5A6A8",
                      transition: "all",
                      transitionDuration: "0.5s",
                      boxShadow: "1px 0px 4px 0px #E5A6A8",
                    }}
                  >
                    <Input
                      width={"100%"}
                      border={"none"}
                      // onKeyDown={handleUserInput}
                      background={"transparent"}
                      size={{ base: "sm", sm: "sm" }}
                      backgroundColor={{
                        base: "transparent",
                        sm: "transparent",
                      }}
                      pr="4.5rem"
                      value={userInput}
                      focusBorderColor="transparent"
                      _focus={{ boxShadow: "none" }}
                      h={{ base: "30px", lg: "40px" }}
                      onKeyDown={(e) =>
                        e.key === "Enter" && handleSendMessage(userInput)
                      }
                      disabled={loading}
                      onChange={(e) => setUserInput(e.target.value)}
                      placeholder="Type your response here..."
                    />

                    <Image
                      w={{ base: "30px", lg: "40px" }}
                      mr={3}
                      src={userInput ? send_active_icon : send_inactive_icon}
                      alt="send_icon"
                      onClick={() => handleSendMessage(userInput)}
                    />
                  </Flex>
                </Box>
                
              </VStack>
            </TabPanel>
            <TabPanel p={0} mt={4}>
              {loadingDraft ? (
                <Spinner size="xl" />
              ) : (
                <>
                  <Draft
                    draftContent={draftContent}
                    projectName={projectName}
                    sectionName={section}
                    setDraftContent={setDraftContent}
                  />
                </>
              )}
            </TabPanel>
            <TabPanel p={0} mt={4}>
              <ImproveSection
                projectName={projectName}
                section={section}
                draftContent={draftContent}
              />
            </TabPanel>
          </TabPanels>
        </Flex>
      </Tabs>
    </Box>
  );
};

export default React.memo(PlanSection);
