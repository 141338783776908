import React from 'react'
import PlaybookDefault from '../PlaybookDefault/PlaybookDefault'

const Gtm = () => {
  const breadcrumbPaths = [
    { label: "Home", href: "/" },
    { label: "AI Assistants", href: "/assistants" },
    { label: "Strategy", href: "/strategy" },
    { label: "Playbook", href: "/strategy/playbook" },
  ];
  return (
    <div>
      <PlaybookDefault
        breadcrumbPaths={breadcrumbPaths}
        fetchHistory={"/strategy/playbook/list"}
        selectChat={"/strategy/playbook"}
        newChatId={"/strategy/playbook"}
        sendMessagee={"/strategy/playbook"}
      />  
    </div>
  )
}

export default Gtm