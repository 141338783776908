import { extendTheme } from '@chakra-ui/react';

const customTheme = extendTheme({
  colors: {
    brand: {
      50: '#F1F3F6',
      100: '#F7ECD9',
      200: '#F68821',  // Accent color for borders
      900: '#333333',  // Main text color
    },
    background: {
      100: '#FFFFFF',  // White background for Navbar and Sidebar
      200: '#F1F3F6',  // Background for sections below Navbar
      300: '#FFE4CB',  // Background for main content
    },
  },
  fonts: {
    heading: 'Poppins, sans-serif',
    body: 'Poppins, sans-serif',
  },
  styles: {
    global: {
      'html, body': {
        bg: '#F1F3F6',  // Overall background color
        color: '#333333',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '24px',
        textAlign: 'left',
      },
    },
  },
  
});



const newcustomTheme = extendTheme({
  colors: {
    brand: {
      50: '#f5f7ff',
      100: '#e6ecff',
      200: '#ccd3ff',
      300: '#99a8ff',
      400: '#667dff',
      500: '#334eff',
      600: '#0030e6',
      700: '#0022b3',
      800: '#001580',
      900: '#000a4d',
    },
    orange: {
      50: '#fff5e6',
      100: '#ffebcc',
      200: '#ffd299',
      300: '#ffb866',
      400: '#ff9e33',
      500: '#ff8400',
      600: '#cc6b00',
      700: '#994f00',
      800: '#663300',
      900: '#331a00',
    },
  },
  fonts: {
    heading: 'Poppins, sans-serif',
    body: 'Poppins, sans-serif',
  },
  styles: {
    global: {
      'html, body': {
        bg: '#f5f7ff',
        color: '#333333',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '24px',
        textAlign: 'left',
      },
    },
  },
});

export default newcustomTheme;
