import React from 'react'
import ChatpagePlaybook from '../ChatpagePlaybook/ChatpagePlaybook'

const Chatpage = () => {
  const breadcrumbPaths = [
    { label: "Home", href: "/" },
    { label: "AI Assistants", href: "/assistants" },
    { label: "HR", href: "/assistants/hr" },
    { label: "Playbook", href: `/hr/playbook` },
  ];
  return (
    <div>
     <ChatpagePlaybook
        breadcrumbPaths={breadcrumbPaths}
        chatHistoryAPIendpoint="/hr/playbook/list"
        chatMessageendpoint="/hr/playbook/"
        chatendpoint="/hr/playbook/chat"
        title="Playbook"
        selectChat="/hr/playbook/"
      />
    </div>
  )
}

export default Chatpage