import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  VStack,
  Heading,
  Text,
  Flex,
  UnorderedList,
  ListItem,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { createDraft } from "../services/api";
import EvaluateOrganization from "./EvaluateOrganization";
import refresh from "../assets/refresh.svg";
const DraftSection = ({
  projectName,
  sectionName,
  draftContent,
  setDraftContent,
}) => {
  const [draft, setDraft] = useState(draftContent || ""); // Initialize with draftContent from parent if available
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    console.log("DraftSection: projectName", projectName);
    console.log("DraftSection: draftContent", draftContent);
  }, [projectName, sectionName, draft]);

  const handleCreateDraft = async () => {
    setLoading(true);
    const draft_request =
      "Please create the high quality draft based on the given data";
    try {
      const response = await createDraft(
        projectName,
        sectionName,
        draft_request
      );
      console.log("Draft created:", response);
      if (response && typeof response === "object") {
        setDraft(response);
        setDraftContent(response);
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error creating draft:", error);
      if (
        error.response &&
        (error.response.status === 500 ||
          error.response.data.message === "Internal Server Error")
      ) {
        // onOpen(); // Open the modal for server error
      } else {
        // Handle other types of errors
        console.error("Error details:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    onClose();
    handleCreateDraft(); // Re-attempt to create draft when modal is closed
  };

  const renderSection = (section) => {
    // Replace underscores with spaces for the section name
    const sectionTitle = section.replace(/_/g, " ");
    console.log(section);

    return (
      <Box key={section}>
        <Heading
          as="h2"
          fontSize={"24px"}
          textTransform={"capitalize"}
          fontWeight={400}
          size="lg"
          mt={0}
          mb={2}
        >
          {sectionTitle}
        </Heading>
        {Object.entries(draftContent[section]).map(([key, value]) => {
          // Replace underscores with spaces for the key names
          const keyTitle = key.replace(/_/g, " ");

          return (
            <Box key={key} mb={4}>
              <Heading as="h3" fontWeight={600} fontSize={"16px"} mt={3} mb={2}>
                {keyTitle}
              </Heading>
              {/* Check if value is an object, array, or string and render appropriately */}
              {typeof value === "object" && !Array.isArray(value) ? (
                <>
                  <Text mb={2} fontWeight={400} fontSize={"14px"}>
                    {value.Description}
                  </Text>
                  {value.KeyPoints && Array.isArray(value.KeyPoints) && (
                    <UnorderedList pl={4} fontWeight={400} fontSize={"14px"}>
                      {value.KeyPoints.map((point, index) => (
                        <ListItem key={index}>{point}</ListItem>
                      ))}
                    </UnorderedList>
                  )}
                </>
              ) : typeof value === "string" ? (
                <Text mb={2}>{value}</Text>
              ) : (
                <Text mb={2}>Unsupported format</Text>
              )}
            </Box>
          );
        })}
      </Box>
    );
  };

  const renderDraftContent = () => {
    if (!draftContent || Object.keys(draftContent).length === 0) {
      return null; // Don't display any message when there is no content, just show the button
    }

    return Object.keys(draftContent).map((section) => renderSection(section));
  };

  return (
    <Flex display={"flex"} flexDirection={{ base: "column", lg: "row" }}>
      <Box
        // w="-webkit-fill-available"
        py={{ base: 0, lg: 4 }}
        px={{ base: 8, lg: 0 }}
        bg="transparent"
        borderRadius="md"
        width={{ base: "100%", lg: "70%" }}
        overflowY="auto"
        boxShadow={"none"}
        // maxHeight={{ base: "200px", lg: "500px" }}
        className="hideScrollbar"
      >
        <VStack align="start" spacing={4} position={"relative"}>
          <Flex
            justifyContent={"flex-end"}
            position={loading ? "" : draft ? "absolute" : ""}
            right={0}
          >
            <Button
              onClick={handleCreateDraft}
              colorScheme="red"
              mt={0}
              fontWeight={400}
              boxShadow={"none"}
              isLoading={loading}
              gap={"0.5rem"}
              // position={}
            >
              {loading
                ? "Creating Draft..."
                : draft
                ? "Update"
                : "Create Draft"}
              {loading ? (
                ""
              ) : draft ? (
                <>
                  <Image src={refresh} alt="refresh icon" />
                </>
              ) : (
                ""
              )}
            </Button>
          </Flex>
          <Box>{renderDraftContent()}</Box>
        </VStack>
        <Box mt={4}></Box>
      </Box>
      <Box
        w={{ base: "100%", lg: "400px" }}
        className="hideScrollbar"
        backgroundColor={"transparent"}
        ml={{ base: 0, lg: 8 }}
        mt={{ base: 4, lg: 0 }}
      >
        <EvaluateOrganization
          projectName={projectName}
          section={sectionName}
          draftContent={draftContent}
        />
      </Box>
      {/* <Modal isOpen={isOpen} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Text>Server Error</Text>
            <Text onClick={handleModalClose}>X</Text>
          </ModalHeader>
          <ModalBody>
            <Text>Server is busy. Please try again.</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="orange" mr={3} onClick={handleModalClose}>
              Okay
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}
    </Flex>
  );
};

export default DraftSection;
