import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { setToken } from "../reducers/authReducer";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import api, { conversation_api } from "./apiClient";

const useApiClient = () => {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();

  const getUserId = () => {
    if (accounts && accounts.length > 0) {
      return accounts[0].localAccountId; // or accounts[0].localAccountId
    }
    return null;
  };

  const renewToken = async () => {
    if (accounts && accounts.length > 0) {
      const account = accounts[0];
      const accessTokenRequest = {
        scopes: [
          "https://webapptest1.onmicrosoft.com/tasks-api/tasks.read",
          "https://webapptest1.onmicrosoft.com/tasks-api/tasks.write",
        ],
        account: account,
      };

      try {
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        const token = response.accessToken;

        // Store the new token in localStorage and Redux
        localStorage.setItem("authToken", token);
        dispatch(setToken(token));

        return token;
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          try {
            const response = await instance.acquireTokenPopup(accessTokenRequest);
            const token = response.accessToken;

            // Store the new token in localStorage and Redux
            localStorage.setItem("authToken", token);
            dispatch(setToken(token));

            return token;
          } catch (err) {
            console.error("Popup token acquisition failed:", err);
            throw err;
          }
        } else {
          console.error("Token renewal failed:", error);
          throw error;
        }
      }
    }
    return null;
  };

  const ensureTokenAndSetHeader = async (config) => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      const newToken = await renewToken();
      if (!newToken) {
        // No token available, so redirect to login
        instance.loginRedirect({
          scopes: [
            "https://webapptest1.onmicrosoft.com/tasks-api/tasks.read",
            "https://webapptest1.onmicrosoft.com/tasks-api/tasks.write",
          ],
        });
        return Promise.reject("Redirecting to login");
      }
      config.headers.Authorization = `Bearer ${newToken}`;
    } else {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  };

  // Handling 401 Errors
  const handleResponseError = async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {
      try {
        const newToken = await renewToken();
        if (newToken) {
          // Update the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return api(originalRequest); // Retry the original request
        }
      } catch (err) {
        // Redirect to login if token renewal fails
        instance.loginRedirect({
          scopes: [
            "https://webapptest1.onmicrosoft.com/tasks-api/tasks.read",
            "https://webapptest1.onmicrosoft.com/tasks-api/tasks.write",
          ],
        });
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  };

  api.interceptors.request.use(ensureTokenAndSetHeader, (error) =>
    Promise.reject(error)
  );
  conversation_api.interceptors.request.use(ensureTokenAndSetHeader, (error) =>
    Promise.reject(error)
  );

  // Attach the response interceptor
  api.interceptors.response.use((response) => response, handleResponseError);
  conversation_api.interceptors.response.use(
    (response) => response,
    handleResponseError
  );

  return { api, conversation_api, getUserId };
};

export default useApiClient;
