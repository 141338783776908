import React from 'react'
import ChatpagePlaybook from '../ChatpagePlaybook/ChatpagePlaybook'

const ChatPage = () => {
  const breadcrumbPaths = [
    { label: "Home", href: "/" },
    { label: "AI Assistants", href: "/assistants" },
    { label: "Sales", href: "/sales" },
    { label: "Playbook", href: `/sales/playbook/` },
  ];
  return (
    <div>
      <ChatpagePlaybook
        breadcrumbPaths={breadcrumbPaths}
        chatHistoryAPIendpoint="/sales/playbook/list"
        chatMessageendpoint="/sales/playbook/"
        chatendpoint="/sales/playbook/chat"
        title="Playbook"
        selectChat="/sales/playbook/"
      />
    </div>
  );
};
export default ChatPage