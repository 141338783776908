'use client'

import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  Image,
  InputRightElement,
  InputLeftElement,
  InputGroup,
  Checkbox,
  ChakraProvider,
} from '@chakra-ui/react'
import googleIcon from '../assets/Signup/google.svg'
import wadhwaniImage from "../assets/wadhwani.png";
import email from '../assets/Signup/email.svg'

import EntrepreneurCarousel from '../components/EntrepreneurCarousel';


export default function Signup() {
  return (
    <Box width={"100vw"} overflowY={"hidden"} position={'relative'} backgroundColor={"#FEF8F5"}>
      <Container
        as={SimpleGrid}
        maxW={'9xl'}
// width={"100vw"}
        height={"100vh"}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 0 }}
   
        py={{ base: 10, sm: 20, lg: 0 }}>
        <Stack  display={"flex"}  flexDirection={"column"} justifyContent={"center"}>
    <Image
style={{width:"150px"}}
      src={wadhwaniImage}
    />
          <ChakraProvider>

          <EntrepreneurCarousel/>
          </ChakraProvider>
        </Stack>
        <Stack
width={"100%"}
display={"flex"} flexDirection={"column"} justifyContent={"center"}
backgroundColor={"white"}
          rounded={'xl'}
          marginY={"auto"}
          p={{ base: 4, sm: 20, md: 48 }}
          spacing={{ base: 5 }}
       
          marginX={"auto"}
          height={"95%"}
          >
          <Stack spacing={4} >
            <Heading
              color={'gray.800'}
              lineHeight={1.1}
              marginX={"auto"}
              fontWeight={"500"}
              fontFamily={"poppins"}
              fontSize={{ base: '2xl', sm: '3xl', md: '2xl' }}>
     Create an account
       
            </Heading>
       
          </Stack>
          <Box as={'form'} mt={2}>
            <Stack spacing={4}>
            <InputGroup>
    <InputLeftElement pointerEvents='none' top={1.5}                 left={2} color='gray.300' fontSize='1.2em'>
      <Image 
      src={googleIcon}
      style={{width:"25px"}}
                 
       />
    </InputLeftElement>
    <Input paddingInline={"50px"} placeholder='Continue with Google' fontFamily={"poppins"} paddingInlineStart={"40px"} border={"1px solid #F2672A"} _placeholder={{color:"#333333"}} readOnly cursor={"pointer"} color={"#101828"} paddingX={"45px"}  borderRadius={"12px"} paddingY={"25px"}  />
    <InputRightElement top={1}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><path d="M5 12h14"/><path d="m12 5 7 7-7 7"/></svg>
    </InputRightElement>
  </InputGroup>
              <Text marginX={"auto"} color={"#666666"} fontWeight={400}>Or</Text>
              <InputGroup>
    <InputLeftElement pointerEvents='none' color='gray.300' top={1} fontSize='1.2em'>
    <Image 
      src={email}
      style={{width:"25px"}}
   
       />
    </InputLeftElement>
    <Input placeholder='Enter your Email Id' paddingX={"45px"}  borderRadius={"12px"} paddingY={"25px"} />
  </InputGroup>
            </Stack>
            <Flex display={"flex"} flexDirection={"row"} gap={"6px"} alignItems={"start"} justifyContent={"flex-start"} paddingTop={"30px"}>
              <Checkbox colorScheme='#C01F27' size={"sm"} />
              <Text  fontWeight={400} fontSize={"12px"} color={"#666666"} lineHeight={"1.4em"}>By proceeding below button, you agree to the <br/> <span style={{color:"#000",fontWeight:"500"}} >Terms of use</span> and <span style={{color:"#000",fontWeight:"500"}}>Privacy policy</span></Text>
                   </Flex>
            <Button
              fontFamily={'heading'}
              mt={8}
              w={'full'}
              paddingY={"10px"}
              paddingX={"12px"}
              // bgGradient="linear(to-r, red.400,pink.400)"
              backgroundColor={"#C01F27"}
              fontWeight={400}
              color={'white'}
              _hover={{
                bgGradient: '',
                boxShadow: '',
              }}
              >
              Get Started
            </Button>
          </Box>
          form
        </Stack>
      </Container>

    </Box>
  )
}