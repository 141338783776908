import React from 'react';
import Slider from 'react-slick';
import { Box, Text, VStack } from '@chakra-ui/react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const carouselItems = [
  {
    text: <>Inspiring, educating,<br/> and enabling</>,
    highlight: <>thousands of startup <br/> entrepreneurs</>
  },
  {
    text: <>Join us and unlock<br/>Free 50+courses and</>,
    highlight: <>get guidance with<br/>industry experts</>
  },
  {
    text: <>Inspiring, educating,<br/> and enabling</>,
    highlight: <>thousands of startup <br/> entrepreneurs</>
  }
];

const EntrepreneurCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <Box width={"100%"}>
      <Slider {...settings} className='poppins'>
        {carouselItems.map((item, index) => (
          <Box key={index} textAlign="left" p={4}>
            <VStack align="flex-start" spacing={0}>
              <Text fontSize={["3xl", "3xl", "3xl"]} fontWeight="semibold" color="navy.900" lineHeight="1.2">
                {item.text}
              </Text>
              <Text fontSize={["3xl", "3xl", "3xl"]} fontWeight="semibold" color="orange.500" lineHeight="1.2">
                {item.highlight}
              </Text>
            </VStack>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default EntrepreneurCarousel;