const b2cPolicies = {
    names: {
      signUpSignIn: "B2C_1_signinsignuptest",
      resetPassword: "B2C_1_passresttest",
      editProfile: "B2C_1_profileedittest"
    },
    authorities: {
      signUpSignIn: {
        authority: "https://webapptest1.b2clogin.com/webapptest1.onmicrosoft.com/B2C_1_signinsignuptest"
      },
      resetPassword: {
        authority: "https://webapptest1.b2clogin.com/webapptest1.onmicrosoft.com/B2C_1_passresttest"
      },
      editProfile: {
        authority: "https://webapptest1.b2clogin.com/webapptest1.onmicrosoft.com/B2C_1_profileedittest"
      }
    },
    authorityDomain: "https://webapptest1.b2clogin.com"
  };


  const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_CLIENT_ID,
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [b2cPolicies.authorityDomain],
      redirectUri: process.env.REACT_APP_REDIRECT_URI,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false
    }
  };
  
  export default msalConfig;
  