import { createProject } from "../services/api";

export const SET_QUERY = "SET_QUERY";
export const CLEAR_QUERY = "CLEAR_QUERY";

export const setQuery = (query) => {
  console.log("query", query);
  return {
    type: SET_QUERY,
    payload: query,
  };
};

export const clearQuery = () => {
  return {
    type: CLEAR_QUERY,
    payload: "",
  };
};

export const setProjectName = (projectName) => {
  // Save to local storage
  localStorage.setItem("projectName", projectName);

  return {
    type: "SET_PROJECT_NAME",
    payload: projectName,
  };
};

export const setSectionName = (sectionName) => {
  // Save to local storage
  localStorage.setItem("sectionName", sectionName);

  return {
    type: "SET_SECTION_NAME",
    payload: sectionName,
  };
};

export const createProjectAsync = (projectName) => async (dispatch) => {
  try {
    const response = await createProject(projectName);
    dispatch(setProjectName(projectName));
    return response; // You can handle further actions based on the response if needed
  } catch (error) {
    console.error(
      "Error creating project:",
      error.response?.data || error.message
    );
    throw error;
  }
};
