import React, { useState, useEffect, useRef } from "react";
import { Box, Text } from "@chakra-ui/react";
import { motion, useAnimationControls } from "framer-motion";

const AnnouncementItem = ({ text }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Text
      as="span"
      mx={1}
      fontSize="14px"
      fontWeight="400"
      color={isHovered ? "#F68623" : "#666666"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      transition="color 0.2s"
      cursor="pointer"
    >
      {text}
    </Text>
  );
};

const AnnouncementMarquee = ({ announcements }) => {
  const controls = useAnimationControls();
  const containerRef = useRef(null);
  const [contentWidth, setContentWidth] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      setContentWidth(containerRef.current.scrollWidth);
    }
  }, [announcements]);

  useEffect(() => {
    if (contentWidth > 0) {
      controls.start({
        x: [0, -contentWidth],
        transition: {
          x: {
            repeat: Infinity,
            repeatType: "loop",
            duration: 60,
            ease: "linear",
          },
        },
      });
    }
  }, [contentWidth, controls]);

  return (
    <Box overflow="hidden" width="100%">
      <motion.div
        ref={containerRef}
        animate={controls}
        style={{
          display: "flex",
          whiteSpace: "nowrap",
          willChange: "transform",
        }}
      >
        {announcements.concat(announcements).map((text, index) => (
          <AnnouncementItem key={index} text={text} />
        ))}
      </motion.div>
    </Box>
  );
};

export default AnnouncementMarquee;