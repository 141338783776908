import React, { useEffect } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useDispatch } from 'react-redux';
import { setToken } from "./reducers/authReducer";

const ProtectedRoute = ({ element }) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const isAuthenticated = accounts && accounts.length > 0;

  const storeToken = async () => {
    if (account) {
      const accessTokenRequest = {
        scopes: [
          "https://webapptest1.onmicrosoft.com/tasks-api/tasks.read",
          "https://webapptest1.onmicrosoft.com/tasks-api/tasks.write",
        ],
        account: account,
      };

      try {
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        const token = response.accessToken;

        // Store token in localStorage and Redux
        localStorage.setItem('authToken', token);
        dispatch(setToken(token));
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequest);
        } else {
          console.error("Token acquisition failed:", error);
        }
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      storeToken();
    }
  }, [isAuthenticated, account, instance]);

  if (!isAuthenticated) {
    instance.loginRedirect({
      scopes: [
        "https://webapptest1.onmicrosoft.com/tasks-api/tasks.read",
        "https://webapptest1.onmicrosoft.com/tasks-api/tasks.write",
      ],
    });
    return null; // Render nothing while redirecting
  }

  return element;
};

export default ProtectedRoute;
