import React from 'react'
import PlaybookDefault from '../PlaybookDefault/PlaybookDefault';


const Gtm = () => {
  const breadcrumbPaths = [
    { label: "Home", href: "/" },
    { label: "AI Assistants", href: "/assistants" },
    { label: "HR", href: "/assistants/hr" },
  ];

  return (
    <div>


  <PlaybookDefault
        breadcrumbPaths={breadcrumbPaths}
        fetchHistory={"/hr/playbook/list"}
        selectChat={"/hr/playbook"}
        newChatId={"/hr/playbook"}
        sendMessagee={"/hr/playbook"}
      />
    </div>
  )
}

export default Gtm