import React, { createContext, useContext, useEffect, useState } from "react";

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ userId, children }) => {
  const [ws, setWs] = useState(null);

  useEffect(() => {
    if (!userId) return; // Ensure userId is available

    // const socket = new WebSocket(`wss://genieai.nefer.ai/ws/${userId}`);

    const socket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/${userId}`);

    socket.onopen = () => {
      console.log("WebSocket connection established for user:", userId);
      // socket.send("Hello Server!", userId);
    };

    socket.onclose = (event) => {
      console.log('WebSocket closed:', event);
      console.log("WebSocket connection closed for user:", userId);
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    setWs(socket);

    // Cleanup on component unmount
    return () => {
      socket.close();
    };
  }, [userId]);

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};
