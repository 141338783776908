import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../assets/genie_logo.png';

const generatePDF = (content) => {
  const doc = new jsPDF();

  // Register Roboto fonts
  doc.addFont('https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf', 'Roboto', 'normal', 300);
  doc.addFont('https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf', 'Roboto', 'normal', 400);
  doc.addFont('https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf', 'Roboto', 'normal', 500);
  doc.addFont('https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf', 'Roboto', 'bold', 700);

  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const margin = 20;
  const usableWidth = pageWidth - 2 * margin;

  // Function to add watermark to each page
  const addWatermark = () => {
    const logoWidth = 20;
    const logoHeight = 20;
    doc.addImage(
      logo,
      'PNG',
      pageWidth - logoWidth - 15,
      pageHeight - logoHeight - 15,
      logoWidth,
      logoHeight
    );
  };

  // Function to add a new page
  const addNewPage = () => {
    doc.addPage();
    addWatermark();
    return margin;
  };

  // Add watermark to the first page
  addWatermark();

  // Add title
  doc.setFontSize(24);
  doc.setFont('Roboto', 'bold', 700);
  const title = 'Business Plan';
  const titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const titleX = (pageWidth - titleWidth) / 2;
  doc.text(title, titleX, margin);

  let yOffset = margin + 15;

  if (!Array.isArray(content) || content.length === 0) {
    doc.setFontSize(12);
    doc.setFont('Roboto', 'normal', 400);
    doc.text('No content available', margin, yOffset);
    return doc;
  }

  content.forEach((section) => {
    if (!section.draft || typeof section.draft !== 'object') {
      return; // Skip this section if it doesn't have the expected structure
    }

    const sectionData = section.draft[Object.keys(section.draft)[0]];
    if (!sectionData || typeof sectionData !== 'object') {
      return; // Skip this section if it doesn't have the expected structure
    }

    const sectionTitle = sectionData.Title || 'Untitled Section';
    doc.setFontSize(18);
    doc.setFont('Roboto', 'bold', 700);
    const sectionTitleLines = doc.splitTextToSize(sectionTitle, usableWidth);
    if (yOffset + sectionTitleLines.length * 7 > pageHeight - margin) {
      yOffset = addNewPage();
    }
    doc.text(sectionTitleLines, margin, yOffset);
    yOffset += sectionTitleLines.length * 7 + 5;

    Object.entries(sectionData).forEach(([key, value]) => {
      if (key === "Title") return;

      doc.setFontSize(14);
      doc.setFont('Roboto', 'bold', 700);
      if (yOffset > pageHeight - margin) {
        yOffset = addNewPage();
      }
      doc.text(key, margin, yOffset);
      yOffset += 8;

      doc.setFontSize(12);
      doc.setFont('Roboto', 'normal', 400);

      if (typeof value === "object" && value !== null) {
        if (value.Description) {
          const descriptionLines = doc.splitTextToSize(value.Description, usableWidth);
          if (yOffset + descriptionLines.length * 7 > pageHeight - margin) {
            yOffset = addNewPage();
          }
          doc.text(descriptionLines, margin, yOffset);
          yOffset += descriptionLines.length * 7;
        }

        if (Array.isArray(value.KeyPoints)) {
          value.KeyPoints.forEach((point) => {
            const bulletPoint = `• ${point}`;
            const pointLines = doc.splitTextToSize(bulletPoint, usableWidth - 5);
            if (yOffset + pointLines.length * 7 > pageHeight - margin) {
              yOffset = addNewPage();
            }
            doc.text(pointLines, margin + 5, yOffset);
            yOffset += pointLines.length * 7;
          });
        }
      } else if (typeof value === "string") {
        const valueLines = doc.splitTextToSize(value, usableWidth);
        if (yOffset + valueLines.length * 7 > pageHeight - margin) {
          yOffset = addNewPage();
        }
        doc.text(valueLines, margin, yOffset);
        yOffset += valueLines.length * 7;
      }

      yOffset += 5;
    });

    yOffset += 10;
  });

  return doc;
};

export default generatePDF;