import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  VStack,
  Button,
  Spinner,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  HStack,
  Flex,
  Image,
} from "@chakra-ui/react";
import {
  improveSectionArticles,
  improveSectionYoutube,
  loadImproveArticles,
  loadImproveyoutube,
} from "../services/api";

const shortenText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + '...';
};

const parseImprovements = (improvements) => {
  if (typeof improvements === 'string') {
    try {
      return JSON.parse(improvements.replace(/```json\n?|\n?```/g, ''));
    } catch (error) {
      console.error("Failed to parse improvements JSON", error);
      return {};
    }
  }
  return improvements || {};
};

const renderContent = (articlesData, youtubeData) => {
  const articleImprovements = parseImprovements(articlesData?.response?.response?.improvements);
  const youtubeImprovements = parseImprovements(youtubeData?.youtube_recommendation?.response?.response?.improvements);
  const allImprovements = { ...articleImprovements, ...youtubeImprovements };

  const articleLinks = articlesData?.articles_recommendation?.response?.response?.links || [];
  const youtubeLinks = youtubeData?.youtube_recommendation?.response?.response?.links || [];

  console.log(articleLinks)

  return (
<Accordion defaultIndex={[0]} allowMultiple width="100%" maxW={{base:"100%",lg:"80rem"}} overflowY={"scroll"} maxHeight={"500px"} className="hideScrollbar" margin="auto" >
{Object.entries(allImprovements).map(([keyword, points], idx) => (
        <AccordionItem key={idx} className="AccordionPanel" >
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    {keyword}
                  </Box>
                  {isExpanded ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#C01F27" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M5 12h14" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#C01F27" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M5 12h14" />
                      <path d="M12 5v14" />
                    </svg>
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg="#FEF8F5" >
                <Flex gap="20px" p="1em" display={"flex"} flexDirection={{base:'column',lg:"row"}}>
                  <Box display="flex" flexDirection="column" gap="8px" className="improveListDiv" width={{base:"100%",lg:"50%"}}>
                    <p style={{ fontSize: "16px" }}>How To Improve:</p>
                    <ul className="ImproveList" style={{marginLeft:"16px"}}>
                      {Object.values(points).map((point, idx) => (
                        <li key={idx} style={{ paddingBottom: "10px" }}>{point}</li>
                      ))}
                    </ul>
                  </Box>
                  <Box className="improveListDiv" display="flex" flexDirection="column" gap="8px" width={{base:"100%",lg:"50%"}}>
                
                    <Heading as="h4" fontWeight={400} size="md" mb={2}>Resources</Heading>
                    <Box width="100%" overflowX="auto" className="hideScrollbar">
                      <HStack spacing={4} py={4}>
                        {[ ...youtubeLinks,...articleLinks]
                          .filter(link => link.keyword === keyword)
                          .map((link, idx) => (
                            <Box
                              key={idx}
                              minWidth="220px"
                              height="220px"
                              p={4}
                              bg="white"
                              borderRadius="md"
                              display="flex"
                              flexDirection="column"
                              gap="6px"
                              justifyContent="space-between"
                            >
                              {link.thumbnail && (
                                <Image src={link.thumbnail} borderRadius="12px" width="120px" />
                              )}
                              <Heading as="h5" fontSize={"12px"} fontWeight={400} size="sm" my="4px">
                                {shortenText(link.description || link.name, 55)}
                              </Heading>
                              <Link
                                href={link.url}
                                isExternal
                                textDecoration="underline"
                                color="#C01F27"
                                fontSize="12px"
                              >
                                {link.description ? 'Go to Video' : 'Read Article'}
                              </Link>
                            </Box>
                          ))}
                      </HStack>
                    </Box>
                  </Box>
                </Flex>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

const ImproveSection = ({ projectName, section, draftContent }) => {
  const [loading, setLoading] = useState(false);
  const [improveDataArticles, setImproveDataArticles] = useState(null);
  const [improveDataYoutube, setImproveDataYoutube] = useState(null);


  useEffect(() => {
    if (draftContent) {
      // const storedArticles = sessionStorage.getItem(`articles_${section}`);
      // const storedYoutube = sessionStorage.getItem(`youtube_${section}`);
      
      // if (storedArticles) {
      //   setImproveDataArticles(JSON.parse(storedArticles));
      // } else {
        fetchImproveArticles();
      // }
      
      // if (storedYoutube) {
      //   setImproveDataYoutube(JSON.parse(storedYoutube));
      // } else {
        fetchImproveYoutube();
      // }
    }
  }, [section, draftContent]);

  const fetchImproveArticles = async () => {
    try {
      const response = await loadImproveArticles(projectName, section);
      setImproveDataArticles(response);
      // sessionStorage.setItem(`articles_${section}`, JSON.stringify(response));
    } catch (error) {
      console.error("Error fetching improve articles data:", error);
    }
  };


  const fetchImproveYoutube = async () => {
    try {
      const response = await loadImproveyoutube(projectName, section);
      setImproveDataYoutube(response);
      // sessionStorage.setItem(`youtube_${section}`, JSON.stringify(response));
    } catch (error) {
      console.error("Error fetching improve YouTube data:", error);
    }
  };

  const handleImprove = async () => {
    setLoading(true);
    try {
      await handleImproveArticles();
      await handleImproveYoutube();
    } catch (error) {
      console.error("Error in handleImprove:", error);
    } finally {
      setLoading(false);
    }
  };


  const handleImproveArticles = async () => {
    const message = "From the given draft pick 5 actionable keywords and find relevant articles for these actionable keywords and only show 3 links for each actionable keywords.";
    try {
      const response = await improveSectionArticles(projectName, section, message);
      setImproveDataArticles(response);
      // sessionStorage.setItem(`articles_${section}`, JSON.stringify(response));
    } catch (error) {
      console.error("Error improving section (articles):", error);
    }
  };

  const handleImproveYoutube = async () => {
    const message = "From the given draft pick 5 actionable keywords and find relevant YouTube videos for these actionable keywords and only show 3 links for each actionable keywords.";
    try {
      const response = await improveSectionYoutube(projectName, section, message);
      setImproveDataYoutube(response);
      // sessionStorage.setItem(`youtube_${section}`, JSON.stringify(response));
    } catch (error) {
      console.error("Error improving section (YouTube):", error);
    }
  };

  return (
    <Box p={4} bg={{base:"transparent",lg:"#ffffff"}} px={{base:"26px",lg:"0px"}}>
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <VStack align="start" spacing={6}>
          {improveDataArticles && improveDataYoutube && renderContent(improveDataArticles, improveDataYoutube)}
          <Box mt={4}>
            <Button
              onClick={handleImprove}
              colorScheme="red"
              mt={2}
              isLoading={loading}
            >
              {loading ? "Getting data... " : "Get Recommendation Data"}
            </Button>
          </Box>
        </VStack>
      )}
    </Box>
  );
};

export default ImproveSection;