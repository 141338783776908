import React from 'react';
import { VStack, Box, Text, Flex, Radio, RadioGroup } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectName, setSectionName } from '../actions/projectActions';

const Sections = () => {
  const projectName = useSelector((state) => state.project.projectName);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const sections = [
    'Company Description',
    'Market Analysis',
    'Organization',
    'Products and Services',
    'Marketing and Sales',
    'Financial Projections',
    'Funding Requirements',
  ];

  const handleNavigation = (section) => {
    let sectionName = section.toLowerCase().replace(/ /g, '_');
    navigate("/business-plan/" + sectionName);
    dispatch(setProjectName(projectName));
    dispatch(setSectionName(sectionName));
  }

  const getActiveSectionValue = () => {
    const activePath = location.pathname.split('/').pop();
    return sections.find(section => 
      section.toLowerCase().replace(/ /g, '_') === activePath
    ) || '';
  }

  return (
    <Flex align="start" spacing={4} bg='white'  h={"fit-content"} gap={1} width={{base:"100%",lg:"200px"}}  display={"flex"} overflowX={{base:"scroll",lg:"hidden"}} className='hideScrollbar' direction={{base:"row",lg:"column"}}>
      <RadioGroup value={getActiveSectionValue()} onChange={handleNavigation} >
        {sections.map((section, index) => {
          const sectionValue = section.toLowerCase().replace(/ /g, '_');
          return (
            <Flex key={index} py={2} alignItems="center">
              <Radio 
                value={section}
                colorScheme="orange"
                size="md"
              >
                <Text fontSize="14px" whiteSpace="nowrap" color='#333333' fontWeight={location.pathname.includes(sectionValue) ? 'medium' : 'normal'}>
                  {section}
                </Text>
              </Radio>
            </Flex>
          );
        })}
      </RadioGroup>
    </Flex>
  );
};

export default Sections;