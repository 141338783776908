import React, { useEffect, useState } from "react";
import {
  VStack,
  Box,
  Text,
  HStack,
  IconButton,
  Collapse,
  Button,
  Image,
  Link,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  FaHome,
  FaUser,
  FaQuestionCircle,
  FaLightbulb,
  FaClipboard,
  FaFileAlt,
  FaPlus,
} from "react-icons/fa";
import { fetchProjects } from "../services/api";
import { NavLink } from "react-router-dom";
import create_new from "../assets/create_new.png";
import { setProjectName, setSectionName } from "../actions/projectActions";

const SidebarItem = ({ icon, label, children, isOpen, onToggle }) => (
  <Box w="full">
    <HStack
      p={4}
      bg={isOpen ? "#F7ECD9" : "white"}
      borderLeft={isOpen ? "6px solid #F68821" : "none"}
      cursor="pointer"
      onClick={onToggle}
      _hover={{ bg: "#F7ECD9" }}
      justify="space-between"
    >
      <HStack spacing={3}>
        {icon}
        <Text>{label}</Text>
      </HStack>
      <IconButton
        icon={isOpen ? <MinusIcon /> : <AddIcon />}
        size="sm"
        variant="ghost"
        aria-label={isOpen ? "Collapse" : "Expand"}
      />
    </HStack>
    <Collapse in={isOpen}>
      <VStack align="start" pl={8} bg="#F7ECD9" spacing={2} py={2}>
        {children}
      </VStack>
    </Collapse>
  </Box>
);

const Sidebar = ({ onOpenNewPlanModal }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [projects, setProjects] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    const loadProjects = async () => {
      try {
        const response = await fetchProjects();
        setProjects(response.projects);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    loadProjects();
  }, []);

  const handleContinue = (project) => {
    console.log("Project: ", project);
    // dispatch(setProjectName(project));
    // dispatch(setSectionName("company_description"))
    // localStorage.setItem('projectName', projectName);
    // localStorage.setItem('sectionName', 'company_description');
    // navigate(`/business-plan/company_description`);
  };

  return (
    <VStack
      align="start"
      bg="white"
      w={{ base: "full", md: "250px" }}
      p={4}
      spacing={0}
      boxShadow="md"
    >
      <SidebarItem
        // icon={<FaHome />}
        label="Home"
        isOpen={openIndex === 0}
        onToggle={() => handleToggle(0)}
      />
      <SidebarItem
        // icon={<FaUser />}
        label="Profile"
        isOpen={openIndex === 1}
        onToggle={() => handleToggle(1)}
      />
      <SidebarItem
        // icon={<FaQuestionCircle />}
        label="General Query"
        isOpen={openIndex === 2}
        onToggle={() => handleToggle(2)}
      />
      <SidebarItem
        // icon={<FaLightbulb />}
        label="Programs"
        isOpen={openIndex === 3}
        onToggle={() => handleToggle(3)}
      />
      <SidebarItem
        // icon={<FaClipboard />}
        label="Ideas"
        isOpen={openIndex === 4}
        onToggle={() => handleToggle(4)}
      />
      <SidebarItem
        // icon={<FaClipboard />}
        label="Plans"
        isOpen={openIndex === 5}
        onToggle={() => handleToggle(5)}
      >
        {projects &&
          projects.length > 0 &&
          projects.map((project, index) => {
            return (
              <Box key={index} pl={4} py={2} cursor={"pointer"} onClick={() => handleContinue(project)}>
                {/* <Link
                  as={NavLink}
                  to={`/plan/summary?project=${project}`}
                  _hover={{ textDecoration: "none" }}
                > */}
                  <Text fontSize="md" fontWeight="medium" color="black">
                    {project}
                  </Text>
                {/* </Link> */}
              </Box>
            );
          })}

        <HStack cursor="pointer" onClick={onOpenNewPlanModal}>
          <Image
            src={create_new}
            alt="Create New"
            boxSize="20px"
          />
          <Text color="#F68821">Create New</Text>
        </HStack>
      </SidebarItem>
      <SidebarItem
        // icon={<FaFileAlt />}
        label="Pitches"
        isOpen={openIndex === 6}
        onToggle={() => handleToggle(6)}
      />
      <SidebarItem
        // icon={<FaFileAlt />}
        label="Playbooks"
        isOpen={openIndex === 7}
        onToggle={() => handleToggle(7)}
      />
    </VStack>
  );
};

export default Sidebar;
