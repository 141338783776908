import React from 'react';
import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useColorModeValue,
  Image
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FaRegUser, FaBook, FaRegCalendarAlt, FaAngleDown } from 'react-icons/fa';
import wadhwaniImage from '../assets/wadhwani.png';

const NavBar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box bg="white" px={4} boxShadow="md">
      <Flex h={20} alignItems="center" justifyContent="space-between">
        <Box pl={16} >
        <Link href="/" textDecoration={"none"}>
          <Image src={wadhwaniImage} alt="Wadhwani Foundation" style={{ height: '40px' }} />
          </Link>
        </Box>

        <HStack spacing={8} alignItems="center">
          <HStack as="nav" spacing={4} display={{ base: 'none', md: 'flex' }}>
            <Menu>
              <MenuButton as={Button} rightIcon={<FaAngleDown />} color={"#6F6F6F"} variant="ghost">
                Events
              </MenuButton>
              <MenuList>
                <MenuItem>Event 1</MenuItem>
                <MenuItem>Event 2</MenuItem>
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton as={Button} rightIcon={<FaAngleDown />} color={"#6F6F6F"} variant="ghost">
                Expert Network
              </MenuButton>
              <MenuList>
                <MenuItem>Expert 1</MenuItem>
                <MenuItem>Expert 2</MenuItem>
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton as={Button} rightIcon={<FaAngleDown />} color={"#6F6F6F"} variant="ghost">
                Library
              </MenuButton>
              <MenuList>
                <MenuItem>Library Item 1</MenuItem>
                <MenuItem>Library Item 2</MenuItem>
              </MenuList>
            </Menu>
          </HStack>
          <Flex alignItems="center">
          <Button
            width="91px"
            height="32px"
            padding="0px 16px"
            gap="8px"
            borderRadius="4px"
            border="1px solid"
            borderColor="var(--Primary-Red, #BF2026)"
            backgroundColor="transparent"
            color="#BF2026"
            _hover={{ bg: "#BF2026", color: "white" }}
          >
            Register
          </Button>
        </Flex>
        </HStack>
        
      </Flex>
    </Box>
  );
};

export default NavBar;


