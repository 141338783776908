import { Heading, Text, UnorderedList, OrderedList, ListItem, Box } from "@chakra-ui/react";
import React from "react";

const FormatMessageContent = (content) => {
  if (!content) return null;
  content = content.replace(/_/g, " ");

  const lines = content.split("\n").filter((line) => line.trim() !== "");
  
  let inList = false;
  let listItems = [];
  let listType = null;

  const processedLines = lines.reduce((acc, line, index) => {
    // Remove hashtags from the beginning and all asterisks from the line
    line = line.replace(/^#+\s*/, "").replace(/\*/g, "").trim();

    if (line.match(/^(\d+\.)\s/)) {
      if (!inList || listType !== 'ordered') {
        if (inList) acc.push(renderList(listItems, listType));
        listItems = [];
        inList = true;
        listType = 'ordered';
      }
      listItems.push(line.replace(/^\d+\.\s/, ""));
    } else if (line.startsWith("- ")) {
      if (!inList || listType !== 'unordered') {
        if (inList) acc.push(renderList(listItems, listType));
        listItems = [];
        inList = true;
        listType = 'unordered';
      }
      listItems.push(line.replace("- ", ""));
    } else {
      if (inList) {
        acc.push(renderList(listItems, listType));
        listItems = [];
        inList = false;
        listType = null;
      }

      if (line.match(/^Components of a Strategic Playbook/i)) {
        acc.push(
          <Heading as="h4" size="sm" mt={6} mb={4} key={index}>
            {line}
          </Heading>
        );
      } else if (line.match(/^\d+\.\s/)) {
        acc.push(
          <Heading as="h2" size="lg" mt={4} mb={2} key={index}>
            {line}
          </Heading>
        );
      } else if (line.includes(":")) {
        const [title, description] = line.split(":");
        acc.push(
          <Box key={index} mb={2}>
            <Text as="medium">{title.trim()}:</Text>
            <Text as="span" ml={1}>{description.trim()}</Text>
          </Box>
        );
      } else {
        acc.push(
          <Text mb={2} key={index}>
            {line}
          </Text>
        );
      }
    }

    return acc;
  }, []);

  if (inList) {
    processedLines.push(renderList(listItems, listType));
  }

  return processedLines;
};
const renderList = (items, type) => {
  const ListComponent = type === 'ordered' ? OrderedList : UnorderedList;
  return (
    <ListComponent mt={2} mb={4} spacing={2}>
      {items.map((item, idx) => (
        <ListItem key={idx}>{item}</ListItem>
      ))}
    </ListComponent>
  );
};

export default FormatMessageContent;