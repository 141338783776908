import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, VStack, Text, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Spinner, Input, IconButton, Avatar } from '@chakra-ui/react';
import { createProjectAsync, setProjectName as setProjectNameRedux } from '../actions/projectActions';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { chatWithProject, loadHistory } from '../services/api';
import { ArrowUpIcon } from '@chakra-ui/icons';
import genie_logo from "../assets/genie_logo.png";

const CreateBusinessPlan = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [projectName, setProjectNameState] = useState('');
  const [sectionName, setSectionName] = useState('summary');
  const [conversation, setConversation] = useState([{ role: 'assistant', content: 'What is the name of your business / organisation?' }]);
  const [userInput, setUserInput] = useState('');
  const [isProjectCreated, setIsProjectCreated] = useState(false);
  const [isFinalMessage, setIsFinalMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const navigate = useNavigate();
  const conversationEndRef = useRef(null);

  useEffect(() => {
    if (isProjectCreated && projectName) {
      dispatch(setProjectNameRedux(projectName));
      dispatch(setSectionName(sectionName));
      localStorage.setItem('projectName', projectName);
      localStorage.setItem('sectionName', sectionName);
    }
  }, [dispatch, isProjectCreated, projectName, sectionName]);

  useEffect(() => {
    if (isOpen && isProjectCreated) {
      fetchChatHistory(projectName, sectionName);
    }
  }, [isOpen, isProjectCreated, projectName, sectionName]);

  useEffect(() => {
    if (conversationEndRef.current) {
      conversationEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversation, loadingHistory]);

  const fetchChatHistory = async (projectName, sectionName) => {
    setLoadingHistory(true);
    try {
      const response = await loadHistory(projectName, sectionName);
      setConversation(response.history);
    } catch (error) {
      console.error('Error fetching chat history:', error.response?.data || error.message);
    }
    setLoadingHistory(false);
  };

  const handleCreateProject = async (name) => {
    setLoading(true);
    try {
      await dispatch(createProjectAsync(name));
      setProjectNameState(name);
      setIsProjectCreated(true);
      handleSendMessage(`My business name is ${name}`, name, sectionName);
    } catch (error) {
      setLoading(false);
      console.error('Error creating project:', error.response?.data || error.message);
      setConversation([
        { role: 'assistant', content: error.response?.data?.error || 'Error creating project' }
      ]);
    }
  };

  const handleSendMessage = async (initialMessage, name, section) => {
    const message = initialMessage || userInput;
    const currentProjectName = name || projectName;
    const currentSectionName = section || sectionName;
    if (!message || !currentProjectName || !currentSectionName) return;

    const updatedConversation = [
      ...conversation,
      { role: 'user', content: message }
    ];

    setUserInput('');
    setConversation(updatedConversation);
    setLoading(true);
    try {
      const response = await chatWithProject(currentProjectName, currentSectionName, message);
      setConversation([
        ...updatedConversation,
        { role: 'assistant', content: response.response }
      ]);

      if (response.next_section) {
        // navigate(`/business-plan/${response.next_section}`);
        setSectionName(response.next_section);
        localStorage.setItem('sectionName', response.next_section);
      }

      if (response.response.toLowerCase().includes('click on continue')) {
        setIsFinalMessage(true);
      }
    } catch (error) {
      console.error('Error sending message:', error.response?.data || error.message);
      setConversation([
        ...updatedConversation,
        { role: 'assistant', content: 'Error sending message' }
      ]);
    }
    setLoading(false);
  };

  const handleUserInput = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (!isProjectCreated) {
        handleCreateProject(userInput);
      } else {
        handleSendMessage();
      }
    }
  };

  const handleButtonClick = () => {
    if (!isProjectCreated) {
      handleCreateProject(userInput);
    } else {
      handleSendMessage();
    }
  };

  const handleContinue = () => {
    setSectionName('company_description');
    localStorage.setItem('sectionName', 'company_description');
    onClose()
    navigate(`/business-plan/company_description`);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={["sm", "md", "lg", "xl", "2xl"]} >
        <ModalOverlay />
        <ModalContent py={4} maxH="90vh" overflowY="auto">
          <ModalHeader>Create New Business Plan</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="start" width="100%">
              {loadingHistory ? (
                <Spinner size="xl" />
              ) : conversation.length === 0 ? (
                <Text color="gray.500" alignSelf="center" justifyContent={"center"}>
                  What is the name of your business / organisation?
                </Text>
              ) : (
                conversation.map((msg, index) => (
                  <Flex key={index} align="flex-start" w="100%">
                    {msg.role === 'user' ? (
                      <Avatar h='24px' w="24px" name="User" bg="#FEE6D4" color="#333" mr={3} />
                    ) : (
                      <Avatar h='24px' w="24px" src={genie_logo} mr={3} />
                    )}
                    <Box bg={msg.role === 'user' ? 'white' : '#FEE6D4'} p={4} pt={msg.role === 'user' ? 0 : 4} pl={msg.role === 'user' ? 0 : 4} borderRadius="md" width="100%">
                      <Text>{msg.content}</Text>
                    </Box>
                  </Flex>
                ))
              )}
              <div ref={conversationEndRef} />
            </VStack>
            {isFinalMessage && (
              <Button my={4} colorScheme="red" onClick={handleContinue} isLoading={loading}>
                Continue
              </Button>
            )}
          </ModalBody>
          <ModalFooter>
            {!isFinalMessage && (
              <Box
                bg="#FEE6D4"
                borderRadius="8px"
                padding="8px"
                width="100%"
                display="flex"
                alignItems="center"
                border="1px solid #FEE6D44D"
              >
                <Input
                  placeholder={isProjectCreated ? "Type your response here..." : "Enter business name..."}
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyDown={handleUserInput}
                  border="none"
                  focusBorderColor="none"
                  _focus={{ boxShadow: "none" }}
                  flex="1"
                  bg="white"
                />
                <IconButton
                  icon={<ArrowUpIcon />}
                  aria-label="Send"
                  onClick={handleButtonClick}
                  isLoading={loading}
                  bg="transparent"
                  _hover={{ bg: "transparent" }}
                />
              </Box>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateBusinessPlan;
