import React from 'react'
import Assistant from '../Assistants/Assistanats'
const Finance = () => {
  return (
    <div>
<Assistant title={"Finance"} gtmRoute={"/finance/playbook"} />
    </div>
  )
}

export default Finance