import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from "@azure/msal-browser";
import App from './App';
import './App.css';
import customTheme from './styles/theme';
import store from './store';
import Navbar from './components/Navbar';
import authConfig from './authConfig'; 


// Initialize MSAL
const msalInstance = new PublicClientApplication(authConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <ChakraProvider theme={customTheme}>
        <Navbar />
          <App />
      </ChakraProvider>
    </MsalProvider>
  </Provider>
);
