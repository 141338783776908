import React, { useState } from 'react'
import BreadCrumb from '../components/BreadCrumb/BreadCrumb';
import { Box, Flex,Image,Input,Text } from '@chakra-ui/react';
import attach from "../assets/attach.svg";
import send_active_icon from "../assets/send_active_icon.png";
import send_inactive_icon from "../assets/search_button.png";

const Gtm = () => {
    const [inputValue, setInputValue] = useState("");

    const breadcrumbPaths = [
        { label: 'Home', href: '/' },
        { label: 'AI Assistants', href: '/assistants' },
        // { label: 'Claude', href: '/ai-assistants/claude' },
      ];
    return (
    <Flex gap={"12px"} height={{base:"",sm:"95vh"}} flexDirection={"column"} justifyContent={"flex-start"} py={5} pl={16}>
    <div>
<BreadCrumb paths={breadcrumbPaths} />
    </div>
    <Text fontWeight={500} fontSize={"16px"} textColor={"black"}>
        Create a playbook plan
    </Text>
    <Flex  display={"flex"} justifyContent={"center"} alignItems={"end"} height={"100%"}  width={"100%"} >
    <Box mb={8} pos={"relative"} width={{ base: "100%", sm: "75%",lg:"65%" }}>
              <Flex
                border={"1px solid #D9D9D9"}
                borderRadius={"16px"}
                h={"70px"}
                align={"center"}
                justifyContent={"center"}
_focusWithin={{border:"2px solid #E5A6A8",transition:"all",transitionDuration:"0.5s",boxShadow:"1px 0px 4px 0px #E5A6A8"}}

              >
                <Input
                  placeholder="How can GenieAI help you today?"
                  size={{ base: "sm", sm: "sm" }}
                  bg="white"
                  pr="4.5rem"
                  border={"none"}
                  h={"40px"}
                  onChange={(e) => setInputValue(e.target.value)}
                  focusBorderColor="transparent"
                  _focus={{ boxShadow: "none" }}
                />

                <Image
                  w={{ base: "30px", lg: "30px" }}
                  mr={3}
                  src={inputValue ? send_active_icon : send_inactive_icon}
                  alt="send_icon"
                />
                {/* <Image src={attach} position={"absolute"} right={14} top={5} /> */}
              </Flex>
            </Box>
    </Flex>
    </Flex>
  ) 
}

export default Gtm
