// src/components/MainLayout.js
import React, { useState } from "react";
import { Box, Flex, Heading, Button, Image } from "@chakra-ui/react";
import Sidebar from "../components/Sidebar.js";
import Navbar from "../components/Navigation.js";
import ChatSection from "../components/ChatSection.js";
import NewPlanModal from "../components/NewPlanModal.js";
import TabNavigation from "../components/TabNavigation.js";
import DraftSection from "../components/DraftSection.js";
import ImproveSection from "../components/ImproveSection.js";
import CreateBusinessPlan from "../components/createbusinessplan.js";
import genie_logo from "../assets/genie_logo.png";
import HomePage from "../components/HomePage.js";

const MainLayout = () => {
  const [activeIndex, setActiveIndex] = React.useState(null);

  const [isModalOpen, setModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("input");
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <Box bg="#ffffff">
      <Navbar />
      <Box bg="#ffffff" p={4} px={20} >
        {/* <Box bg="#F7ECD9" p={8} mb={4} mx={{ base: 2, md: 0 }}>
          <Flex alignItems={"center"}>
            <Image
              src={genie_logo}
              alt="genie_logo"
              style={{ height: "55px" }}
              mr={6}
            />
            <Heading as="h2">Genie AI For Entrepreneurs</Heading>
          </Flex>
        </Box> */}
        <Flex direction={{ base: "column", md: "row" }}>
          <Sidebar
            activeIndex={activeIndex}
            handleClick={handleClick}
            onOpenNewPlanModal={handleOpenModal}
          />
          {/* <Box flex="1" p={0}> */}
          {/* <Box bg="white" p={4} boxShadow="md" mb={4}> */}
          {/* <Box display={{ base: "block", md: "none" }} mb={4}>
                <Button
                  onClick={() => handleClick(activeIndex === null ? 0 : null)}
                >
                  Toggle Sidebar
                </Button>
              </Box> */}
          {/* <Flex> */}
          {/* <Box flex="1" p={4} bg="#F1F3F6"> */}
          {/* <TabNavigation setActiveTab={setActiveTab} /> */}
          {/* {activeTab === 'input' && <ChatSection />} */}
          {/* {activeTab === 'draft' && <DraftSection />} */}
          {/* {activeTab === 'improve' && <ImproveSection />} */}
          {/* </Box> */}
          {/* </Flex> */}
          {/* <HomePage/> */}

          <CreateBusinessPlan isOpen={isModalOpen} onClose={handleCloseModal} />

          {/* </Box> */}
          {/* </Box> */}
        </Flex>
      </Box>
    </Box>
  );
};

export default MainLayout;
