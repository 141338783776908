import React from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text, Icon } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

const BreadCrumb = ({ paths }) => {
  return (
    <Breadcrumb separator={<Icon as={ChevronRightIcon} color="gray.500" />} spacing="8px">
      {paths.map((path, index) => (
        <BreadcrumbItem key={index} isCurrentPage={index === paths.length - 1}>
          <BreadcrumbLink
            as={RouterLink}
            to={path.href}
            color={index === paths.length - 1 ? 'orange.500' : 'inherit'}
          >
            <Text fontSize="sm">{path.label}</Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default BreadCrumb;