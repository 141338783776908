import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BusinessPlanPage from './pages/BusinessPlanPage.js';
import SignupPage from './pages/SignupPage.js';
import LoginPage from './pages/LoginPage.js';
import MainLayout from './pages/MainLayout.js';
import PlanPage from './components/PlanPage.js';
import HomePage from './components/HomePage.js';
import AgentContainer from './components/AgentContainer/AgentsContainer.js';
import Conversation from './components/Conversation.js';
import EmailDraft from './components/Email.js';
import SalesDraft from './components/SalesDraft.js';
import Signup from './pages/Signup.js';
import SignIn from './pages/SignIn.js';
import Assistants from './pages/Assistants.js';
import Finance from './components/Finance/Finance.js';
import Hr from './components/HR/Hr.js';
import Sales from './components/Sales/Sales.js';
import Strategy from './components/Strategy/Strategy.js';
import ProductDev from './pages/ProductDev.js';
import Gtm from './pages/Gtm.js';
import GtmA from './components/AgentContainer/Gtm.js';
import GtmS from './components/Strategy/Gtm.js';
import GtmM from './components/Marketing/Gtm.js';
import GtmSales from './components/Sales/Gtm.js';
import GtmH from './components/HR/Gtm.js';
import GtmF from './components/Finance/Gtm.js';
import Marketing from './components/Marketing/Marketing.js';
import SalesCoach from './components/AgentContainer/SalesCoach.js';
import ChatPage from './components/Marketing/ChatPage.js';
import ChatPageH from './components/HR/Chatpage.js';
import ChatPageS from './components/AgentContainer/ChatPage.js';
import ChatPageSales from './components/Sales/ChatPage.js';
import ChatPageF from './components/Finance/ChatPage.js';
import ProtectedRoute from './ProtectedRoute.js'; // Import the ProtectedRoute
import MarketingCoach from './components/Marketing/MarketingCoach.js';
import HrCoach from './components/HR/HrCoach.js';
import StrategyCoach from './components/Strategy/StrategyCoach.js';
import IdeaGenerator from './components/Strategy/IdeaGenerator.js';
import IdeaChallenger from './components/Strategy/IdeaChallenger.js';
import IdeaChallengerChatPage from './components/Strategy/IdeaChallengerChatPage.js';
import IdeaGeneratorChatPage from './components/Strategy/ideaGeneratorChatPage.js';
import IdeaEnhancer from './components/Strategy/ideaEnhancer.js';
import IdeaEnhancerChatPage from './components/Strategy/IdeaEnhancerChatPage.js';
import ChatpageStrategy from './components/Strategy/ChatPafeStrategy.js';
import ChatPageMark from './components/Marketing/ChatPage.js';

const RoutesConfig = () => (
  <Routes>
    <Route path="/home" element={<MainLayout />} />
    <Route path="/signup" element={<Signup />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/" element={<HomePage />} />
    <Route path="/agent-container" element={<AgentContainer />} />
    <Route path="/genie-ai/master-coach" element={<Conversation />} />
    <Route path="/genie-ai/master-coach/:chatId" element={<Conversation />} />
    <Route path="/sales/sales-coach" element={<Conversation />} />
    <Route path="/sales/sales-coach/:chatId" element={<Conversation />} />
    <Route path="/hr/hr-coach" element={<Conversation/>} />
    <Route path="/hr/hr-coach/:chatId" element={<Conversation/>} />
    <Route path="/email-draft" element={<EmailDraft />} />
    <Route path="/sales/sales-draft" element={<SalesDraft />} />
    <Route path="/finance" element={<Finance />} />
    <Route path="/finance/playbook" element={<GtmF />} />
    <Route path="/hr" element={<Hr />} />
    <Route path="/genie-ai/master-coach/playbook/:chatId" element={<ChatPageS />} />
    <Route path="/strategy/strategy-coach" element={<Conversation/>} />
    <Route path="/strategy/strategy-coach/:chatId" element={<Conversation/>} />
    <Route path="/marketing/marketing-coach" element={<Conversation/>} />
    <Route path="/marketing/marketing-coach/:chatId" element={<Conversation/>} />
    <Route path="/hr/playbook" element={<GtmH />} />
    <Route path="/hr/playbook/:chatId" element={<ChatPageH />} />
    <Route path="/sales" element={<Sales />} />
    <Route path="/sales/playbook" element={<GtmSales />} />
    <Route path="/sales/playbook/:chatId" element={<ChatPageSales />} />
    <Route path="/finance/playbook/:chatId" element={<ChatPageF />} />
    <Route path="/strategy" element={<Strategy />} />
    <Route path="/strategy/playbook" element={<GtmS />} />
    <Route path="/strategy/playbook/:chatId" element={<ChatpageStrategy />} />
    <Route path="/strategy/idea-challenger" element={<IdeaChallenger />} />
    <Route path="/strategy/idea-challenger/:chatId" element={<IdeaChallengerChatPage />} />
    <Route path="/strategy/idea-generator" element={<IdeaGenerator />} />
    <Route path="/strategy/idea-generator/:chatId" element={<IdeaGeneratorChatPage />} />
    <Route path="/strategy/idea-enhancer" element={<IdeaEnhancer />} />
    <Route path="/strategy/idea-enhancer/:chatId" element={<IdeaEnhancerChatPage />} />
    <Route path="/marketing" element={<Marketing />} />
    <Route path="/marketing/playbook" element={<GtmM />} />
    <Route path="/marketing/playbook/:chatId" element={<ChatPageMark/>} />
    <Route path="/product-dev" element={<ProductDev />} />
    <Route path="/gtm" element={<Gtm />} />
    <Route path="/business-plan/:section" element={<BusinessPlanPage />} />
    <Route path="/general-conversation" element={<Conversation />} />
    <Route path="/assistants" element={<Assistants />} />
    <Route
      path="/business-plan"
      element={<ProtectedRoute element={<BusinessPlanPage />} />}
    />
  </Routes>
);

export default RoutesConfig;
