// src/api/apiClient.js

import axios from 'axios';
import store from '../store'; // Ensure you have the correct import path for your Redux store

// export const API_URL = "http://localhost:3001"; // Adjust to your backend URL
// export const CONVERSATION_API_URL = "http://localhost:3001/conversation/api"; // Adjust to your backend URL

export const API_URL = process.env.REACT_APP_API_ENDPOINT; // Adjust to your backend URL
export const CONVERSATION_API_URL = process.env.REACT_APP_API_ENDPOINT; // Adjust to your backend URL

const api = axios.create({
  baseURL: API_URL,
});

const conversation_api = axios.create({
  baseURL: CONVERSATION_API_URL,
});

// Interceptor to include the Authorization header
const addAuthHeader = (config) => {
  const state = store.getState();
  const token = state.auth.token || localStorage.getItem('authToken');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

api.interceptors.request.use(addAuthHeader, (error) => Promise.reject(error));
conversation_api.interceptors.request.use(addAuthHeader, (error) => Promise.reject(error));

export default api;
export { conversation_api };
