// src/services/api.js

import api, { API_URL, conversation_api } from "./apiClient"; // Ensure you have the correct import path
// import useApiClient from "./useApiClient";

export const createProject = async (projectName) => {
  const response = await api.post("/businessplan/create_projects", {
    project_name: projectName,
  });
  return response.data;
};

export const sendMessageFinance = async (message) => {
  try {
    const response = await fetch(`${API_URL}/finance_playbook/chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chat_id: "536db1c3-c923-4842-bb46-f27bfbfb2029",
        message: message,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error sending message:", error);
    return { error: "Failed to send message" };
  }
};

export const createDraft = async (projectName, sectionName, draft_request) => {
  try {
    const response = await api.post("/businessplan/create_draft", {
      project_name: projectName,
      section_name: sectionName,
      message: draft_request,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error creating draft:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const loadDraft = async (projectName, sectionName) => {
  const response = await api.post("/businessplan/load_draft", {
    project_name: projectName,
    section_name: sectionName,
  });
  return response.data;
};

export const loadAllDrafts = async (projectName) => {
  const response = await api.post("/businessplan/load_all_drafts", {
    project_name: projectName,
  });
  return response.data;
};

export const improveSectionArticles = async (
  projectName,
  sectionName,
  message
) => {
  const response = await api.post(
    `/businessplan/create_articles_recommendation`,
    {
      project_name: projectName,
      section_name: sectionName,
      message: message,
    }
  );
  return response.data;
};

export const improveSectionYoutube = async (
  projectName,
  sectionName,
  message
) => {
  const response = await api.post(
    `/businessplan/create_youtube_recommendation`,
    {
      project_name: projectName,
      section_name: sectionName,
      message: message,
    }
  );
  return response.data;
};

export const loadImproveArticles = async (projectName, sectionName) => {
  const response = await api.get(`/businessplan/get_articles_recommendation`, {
    params: {
      project_name: projectName,
      section_name: sectionName,
    },
  });
  return response.data;
};

export const loadImproveyoutube = async (projectName, sectionName) => {
  const response = await api.get(`/businessplan/get_youtube_recommendation`, {
    params: {
      project_name: projectName,
      section_name: sectionName,
    },
  });
  return response.data;
};

export const saveImprove = async (projectName, sectionName, data) => {
  const response = await api.post("/save_improve", {
    project_name: projectName,
    section_name: sectionName,
    data,
  });
  return response.data;
};

export const loadHistory = async (projectName, sectionName) => {
  try {
    const response = await api.post("/businessplan/load_history", {
      project_name: projectName,
      section_name: sectionName,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error loading history:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const chatWithProject = async (projectName, sectionName, message) => {
  try {
    const response = await api.post("/businessplan/chat", {
      project_name: projectName,
      section_name: sectionName,
      message,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error chatting with project:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const fetchProjects = async () => {
  try {
    const response = await api.get("/all_projects/list_projects");
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching projects:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const listProjects = async () => {
  try {
    const response = await api.get("/list_projects");
    return response.data;
  } catch (error) {
    console.error(
      "Error listing projects:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const evaluateOrganization = async (projectName, section, message) => {
  const response = await api.post("/businessplan/create_rubric", {
    project_name: projectName,
    section_name: section,
    message: message,
  });
  return response.data;
};

export const loadRubricEvaluation = async (projectName, section) => {
  const response = await api.post("/businessplan/load_rubric", {
    project_name: projectName,
    section_name: section,
  });
  return response.data;
};

export const submitUserData = (data) => {
  return api.post("/responses", data);
};

export const fetchBusinessPlan = () => {
  return api.get("/plan");
};
