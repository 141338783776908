// src/store.js

import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import projectReducer from "./reducers/projectReducer";
import authReducer from "./reducers/authReducer"; // Import the auth reducer

const store = configureStore({
  reducer: {
    project: projectReducer,
    auth: authReducer, // Add the auth reducer here
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;
