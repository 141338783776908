import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Link,
  VStack,
  HStack,
  Input,
  Image,
  Button,
  Flex,
  IconButton,
  Collapse,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import {
  AddIcon,
  MinusIcon,
  ChevronDownIcon,
  ArrowForwardIcon,
} from "@chakra-ui/icons";
import axios from "axios";
import wadhwaniImage from "../assets/wadhwani.png";
import home_icon from "../assets/home_icon.png";
import start_new from "../assets/start_new.png";
import { API_URL } from "../services/apiClient";

const SidebarItem = ({ icon, label, children, isOpen, onToggle }) => (
  <Box w="full">
    <HStack p={4} cursor="pointer" onClick={onToggle} justify="space-between">
      <HStack spacing={3}>
        {icon}
        <Text color={isOpen ? "#FF7A59" : "#333333"}>{label}</Text>
      </HStack>
      <IconButton
        icon={
          isOpen ? (
            <MinusIcon color={"#C01F27"} />
          ) : (
            <AddIcon color={"#C01F27"} />
          )
        }
        size="sm"
        variant={""}
        aria-label={isOpen ? "Collapse" : "Expand"}
      />
    </HStack>
    <Collapse in={isOpen}>
      <VStack align="start" pl={4} bg="#FEF8F5" spacing={2} py={2}>
        {children}
      </VStack>
    </Collapse>
  </Box>
);

const EmailDraft = () => {
  const [companyName, setCompanyName] = useState("");
  const [targetAudience, setTargetAudience] = useState("");
  const [difficulties, setDifficulties] = useState("");
  const [products, setProducts] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [draft, setDraft] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailsList, setEmailsList] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    fetchEmailDrafts();
  }, []);

  const fetchEmailDrafts = async () => {
    try {
      const response = await axios.get(`${API_URL}/get-email-drafts`);
      setEmailsList(response.data.drafts);
    } catch (error) {
      console.error("Error fetching email drafts:", error);
    }
  };

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleGenerateDraft = async () => {
    if (
      !companyName ||
      !targetAudience ||
      !difficulties ||
      !products ||
      !recipientName
    ) {
      alert("All fields are required!");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/draft-email`, {
        company_name: companyName,
        target: targetAudience,
        difficulties,
        products,
        recipients_name: recipientName,
      });
      const newEmail = {
        content: response.data.email_content,
        timestamp: new Date().toISOString(),
      };
      setDraft(newEmail.content);
      setEmailsList([newEmail, ...emailsList]);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleNewEmailChat = () => {
    setCompanyName("");
    setTargetAudience("");
    setDifficulties("");
    setProducts("");
    setRecipientName("");
    setDraft("");
  };

  const handleEmail = (email) => {
    setDraft(email.content);
  };

  const groupEmailsByDate = (emails) => {
    const groupedEmails = {
      today: [],
      yesterday: [],
      thisWeek: [],
      thisMonth: [],
    };

    const today = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const oneWeek = 7 * oneDay;
    const startOfToday = new Date(today.setHours(0, 0, 0, 0));
    const startOfYesterday = new Date(startOfToday - oneDay);
    const startOfWeek = new Date(
      today.setDate(today.getDate() - today.getDay())
    );
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    emails.forEach((email) => {
      const emailDate = new Date(email.timestamp * 1000); // Convert timestamp to milliseconds
      if (emailDate >= startOfToday) {
        groupedEmails.today.push(email);
      } else if (emailDate >= startOfYesterday) {
        groupedEmails.yesterday.push(email);
      } else if (emailDate >= startOfWeek) {
        groupedEmails.thisWeek.push(email);
      } else if (emailDate >= startOfMonth) {
        groupedEmails.thisMonth.push(email);
      }
    });

    return groupedEmails;
  };

  const groupedEmails = groupEmailsByDate(emailsList);

  return (
    <Box bg={"#FFFFFF"} minH={"100vh"}>
      <Flex
        bg="white"
        p={4}
        boxShadow="md"
        align="center"
        justify="space-between"
      >
        <Box pl={5} bg="white">
        <Link href="/" textDecoration={"none"}>
          <Image
            src={wadhwaniImage}
            alt="Wadhwani Foundation"
            style={{ height: "50px" }}
          />
          </Link>
        </Box>
        <HStack spacing={10} color={"6F6F6F"}>
          <Text color={"#6F6F6F"} >
            Home
          </Text>
          <Menu isLazy>
            <MenuButton color={"#F68821"} fontWeight={"500"} >AI Assistants</MenuButton>
            <MenuList>
              {/* MenuItems are not rendered unless Menu is open */}
              <MenuItem>Marketing</MenuItem>
              <MenuItem>Sales</MenuItem>
              <MenuItem>Finance</MenuItem>
              <MenuItem>Human Resource</MenuItem>
            </MenuList>
          </Menu>
          <Text color={"#6F6F6F"}>Expert Network</Text>
          <Text color={"#6F6F6F"}>Learning Library</Text>
          <Text color={"#6F6F6F"}>Programs</Text>
          <Text color={"#6F6F6F"}>Events</Text>
          <Avatar name="Chenna" src="path-to-avatar.jpg" />
        </HStack>
      </Flex>
      <Flex bg={"#FFFFFF"} minH={"100vh"}>
        <VStack
          align="start"
          w={{ base: "full", md: "250px" }}
          p={4}
          spacing={0}
          boxShadow="md"
          bg="#FEF8F5"
        >
          <Link href="/" textDecoration={"none"} mb={2}>
            <Image src={home_icon} alt="" />
          </Link>
          <Box
            color="#FEE6D4"
            w={"-webkit-fill-available"}
            border={"1px solid #FEE6D4"}
          ></Box>
          <HStack
            cursor="pointer"
            onClick={handleNewEmailChat}
            mt={"1em"}
            borderRadius={"8px"}
            bg={"#FEF1E9"}
            w={"-webkit-fill-available"}
            justifyContent={"center"}
          >
            <Text bg={"#FEF1E9"} color={"#C01F27"} my={3}>
              Start New
            </Text>
            <Image src={start_new} alt="Start New" boxSize="20px" />
          </HStack>

          <SidebarItem
            label="Email drafts"
            isOpen={openIndex === 5}
            onToggle={() => handleToggle(5)}
          >
            <Flex
              direction="column"
              bg="#FEF8F5"
              w="-webkit-fill-available"
              justifyContent="space-between"
            >
              <Box>
                <Text fontSize="sm" color="#FF7A59" mb={2}>
                  Today
                </Text>

                {groupedEmails.today.length > 0 &&
                  groupedEmails.today
                    .slice(0, showMore ? groupedEmails.today.length : 5)
                    .map((email, index) => (
                      <Box
                        key={index}
                        pl={4}
                        py={2}
                        cursor={"pointer"}
                        onClick={() => handleEmail(email)}
                      >
                        <Text fontSize="md" fontWeight="medium" color="black">
                          Draft {index + 1}
                        </Text>
                      </Box>
                    ))}
                {groupedEmails.today.length > 5 && (
                  <Flex align="center" mt={4}>
                    <Link
                      color="#F68821"
                      fontSize="sm"
                      onClick={() => setShowMore(!showMore)}
                    >
                      {showMore ? "Show less" : "View more"}
                    </Link>
                    <ChevronDownIcon color="black" ml={2} />
                  </Flex>
                )}
                <Text fontSize="sm" color="#FF7A59" mt={4} mb={2}>
                  Yesterday
                </Text>
                {groupedEmails.yesterday.length > 0 &&
                  groupedEmails.yesterday.map((email, index) => (
                    <Box
                      key={index}
                      pl={4}
                      py={2}
                      cursor={"pointer"}
                      onClick={() => handleEmail(email)}
                    >
                      <Text fontSize="md" fontWeight="medium" color="black">
                        Draft {index + 1}
                      </Text>
                    </Box>
                  ))}
                <Text fontSize="sm" color="#FF7A59" mt={4} mb={2}>
                  This Week
                </Text>
                {groupedEmails.thisWeek.length > 0 &&
                  groupedEmails.thisWeek.map((email, index) => (
                    <Box
                      key={index}
                      pl={4}
                      py={2}
                      cursor={"pointer"}
                      onClick={() => handleEmail(email)}
                    >
                      <Text fontSize="md" fontWeight="medium" color="black">
                        Draft {index + 1}
                      </Text>
                    </Box>
                  ))}
                <Text fontSize="sm" color="#FF7A59" mt={4} mb={2}>
                  This Month
                </Text>
                {groupedEmails.thisMonth.length > 0 &&
                  groupedEmails.thisMonth.map((email, index) => (
                    <Box
                      key={index}
                      pl={4}
                      py={2}
                      cursor={"pointer"}
                      onClick={() => handleEmail(email)}
                    >
                      <Text fontSize="md" fontWeight="medium" color="black">
                        Draft {index + 1}
                      </Text>
                    </Box>
                  ))}
              </Box>
            </Flex>
          </SidebarItem>
          {/* <SidebarItem
            label="Documents"
            isOpen={openIndex === 6}
            onToggle={() => handleToggle(6)}
          />
          <SidebarItem
            label="Drafts"
            isOpen={openIndex === 7}
            onToggle={() => handleToggle(7)}
          /> */}
        </VStack>

        <Box bg="#ffffff" color="#000000" p={8} maxW="800px" mx="auto">
          <Box mb={8}>
            <Heading as="h2" size="lg">
              Draft an initial outreach email
            </Heading>
          </Box>

          <Box mb={4} letterSpacing={2}>
            <Text lineHeight={"50px"}>
              Draft an initial outreach email for my company{" "}
              <Input
                placeholder="Company name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                display="inline-block"
                width="auto"
                mx={2}
                color="black"
                bg="white"
              />
              , targeted at{" "}
              <Input
                placeholder="target audience"
                value={targetAudience}
                onChange={(e) => setTargetAudience(e.target.value)}
                display="inline-block"
                width="auto"
                mx={2}
                color="black"
                bg="white"
              />
              who are experiencing difficulties with{" "}
              <Input
                placeholder="description of the target audience’s problem"
                value={difficulties}
                onChange={(e) => setDifficulties(e.target.value)}
                display="inline-block"
                width="auto"
                mx={2}
                color="black"
                bg="white"
              />
              . Introduce our product/service,{" "}
              <Input
                placeholder="product/service"
                value={products}
                onChange={(e) => setProducts(e.target.value)}
                display="inline-block"
                width="auto"
                mx={2}
                color="black"
                bg="white"
              />
              , and conclude with{" "}
              <Input
                placeholder="call-to-action"
                value={recipientName}
                onChange={(e) => setRecipientName(e.target.value)}
                display="inline-block"
                width="auto"
                mx={2}
                color="black"
                bg="white"
              />
              .
            </Text>
          </Box>

          <Button
            onClick={handleGenerateDraft}
            mt={4}
            colorScheme="red"
            isLoading={loading}
          >
            <ArrowForwardIcon />
          </Button>

          {draft && (
            <Box mt={8}>
              <Heading as="h4" size="md" mb={4}>
                Generated Email:
              </Heading>
              <Text whiteSpace="pre-wrap">{draft}</Text>
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default EmailDraft;
