// App.js
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import RoutesConfig from "./routes.js";
import SideNavigation from "./components/SideNavigation.js";
import { Flex } from "@chakra-ui/react";
import { WebSocketProvider } from './WebSocketContext';
import useApiClient from "./services/useApiClient.js";

function App() {
  const { getUserId } = useApiClient();
  const userId = getUserId();

  return (
    <Router>
      <div style={{ height: "100vh", background: "white" }} className="hideScrollbar" >
        <Flex position={"fixed"} top={"50%"}>
          <SideNavigation />
        </Flex>
        {/* Pass userId and chatId to WebSocketProvider */}
        <WebSocketProvider userId={userId} > {/* Replace 'yourChatIdHere' with the actual chat ID */}
          <RoutesConfig />
        </WebSocketProvider>
      </div>
    </Router>
  );
}

export default App;