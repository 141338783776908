import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import {
  Box,
  Flex,
  Image,
  Input,
  Text,
  VStack,
  Spinner,
  HStack,
  IconButton,
  Collapse,
  Link,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import send_active_icon from "../../assets/send_active_icon.png";
import send_inactive_icon from "../../assets/search_button.png";
import geneiLogo from "../../assets/geneiLogoOrange.svg";
import FormatMessageContent from "../FormatMessage/FormatMessage";
import home_icon from "../../assets/home_icon.png";
import start_new from "../../assets/start_new.png";
import useApiClient from "../../services/useApiClient";
import { shortenText } from "../../services/common";
import userProfile from "../../assets/You.png";

const SidebarItem = ({ label, children, isOpen, onToggle }) => (
  <Box w="full">
    <HStack p={4} cursor="pointer" onClick={onToggle} justify="space-between">
      <Text color={isOpen ? "#FF7A59" : "#333333"}>{label}</Text>
      <IconButton
        icon={
          isOpen ? (
            <MinusIcon color={"#C01F27"} />
          ) : (
            <AddIcon color={"#C01F27"} />
          )
        }
        size="sm"
        variant={""}
        aria-label={isOpen ? "Collapse" : "Expand"}
      />
    </HStack>
    <Collapse in={isOpen}>
      <VStack align="start" pl={4} bg="#FEF8F5" spacing={2} py={2}>
        {children}
      </VStack>
    </Collapse>
  </Box>
);

const ChatpagePlaybook = ({
  breadcrumbPaths,
  chatHistoryAPIendpoint,
  chatMessageendpoint,
  chatendpoint,
  selectChat,
  title,
}) => {
  const { chatId } = useParams();
  const { api, conversation_api } = useApiClient();
  const [inputValue, setInputValue] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const chatContainerRef = useRef(null);

  useEffect(() => {
    fetchChatHistory();
    if (chatId) {
      fetchChatMessages();
    }
  }, [chatId]);

  useEffect(() => {
    scrollToBottom();
  }, [chatId]);

  const fetchChatHistory = async () => {
    try {
      const response = await api.get(`${chatHistoryAPIendpoint}`);
      if (response) {
        const data = response.data;
        console.log(data);
        setChatHistory(data);
        console.log("Chat history fetched successfully", chatHistory);
      } else {
        console.log("Error fetching chat history");
      }
    } catch (error) {
      console.log("Error fetching chat history:", error);
    }
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const fetchChatMessages = async () => {
    scrollToBottom();
    try {
      const response = await api.get(`${chatMessageendpoint}${chatId}`);
      if (response && response.data && response.data.length > 0) {
        const chatData = response.data[0].messages;
        setChatMessages(chatData);
      } else {
        console.log("Error fetching chat messages or no messages found");
      }
    } catch (error) {
      console.log("Error fetching chat messages:", error);
    }
  };

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const sendMessage = async (message, chatId) => {
    try {
      const response = await api.post(`${chatendpoint}`, {
        chat_id: chatId,
        message: message,
      });
      if (response) {
        const data = response.data;
        console.log("Response:", data);
        // Update the chat messages state with the new message and response
        setChatMessages((prev) => [
          ...prev,
          { type: "user", content: message },
          { type: "ai", content: data.response },
        ]);
        return data;
      } else {
        console.error("Error sending message:", response.statusText);
        return { error: "Failed to send message" };
      }
    } catch (error) {
      console.error("Error sending message:", error);
      return { error: "Failed to send message" };
    }
  };

  const handleSendMessage = async () => {
    if (inputValue.trim()) {
      const userMessage = inputValue;
      setInputValue("");
      setIsLoading(true);

      try {
        await sendMessage(userMessage, chatId);
      } catch (error) {
        console.error("Error in handleSendMessage:", error);
        setChatMessages((prev) => [
          ...prev,
          {
            type: "ai",
            content: "Sorry, an error occurred while processing your message.",
          },
        ]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages]);

  const handleSelectChat = (chatId) => {
    // setSelectedChatId(chatId);
    // setChatId(chatId);
    console.log(chatId);
    navigate(`${selectChat}${chatId}`);
    // window.location.href = `/strategy/playbook/${chatId}`;
    // Here you might want to fetch the messages for the selected chat
    // and update the chatMessages state
  };

  // Include other necessary functions like sendMessage, etc.

  return (
    <Flex minH="100vh" bg="white">
      {/* Sidebar */}

      {/* Similar to Gtm component */}

      <Flex
        gap={"12px"}
        height={{ base: "", sm: "101vh" }}
        flexDirection={"column"}
        width={"100%"}
        justifyContent={"space-between"}
        py={5}
        pl={{ base: 3, sm: 16 }}
      >
        <VStack align="stretch" spacing={4} p={5}>
          <BreadCrumb paths={breadcrumbPaths} />
          <Text fontWeight={500} fontSize={"16px"} textColor={"black"}>
            {title} Chat
          </Text>
        </VStack>

        {/* Chat messages */}
        <Flex
          flexDirection="column"
          flex={1}
          overflowY="auto"
          mb={4}
          // height={"50%"}
          width={{ base: "100%", sm: "75%", lg: "65%" }}
          alignSelf="center"
          className="hideScrollbar"
        >
          {chatMessages.map((message, index) => (
            <Box
              key={index}
              alignSelf={message.type === "user" ? "flex-start" : "flex-start"}
              p={2}
              borderRadius="md"
              mb={2}
              ref={chatContainerRef}
              maxWidth={{ base: "80%", lg: "95%" }}
            >
              <Text fontWeight={message.type === "user" ? "bold" : "normal"}>
                {message.type === "user" ? (
                  <Flex
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={"4px"}
                  >
                    <Image
                      width={"28px"}
                      borderRadius={"24px"}
                      src={userProfile}
                    />
                    <Text fontWeight={400} fontSize={"14px"}>
                      You
                    </Text>
                  </Flex>
                ) : (
                  <Flex
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    gap={"4px"}
                  >
                    <Image
                      width={"28px"}
                      borderRadius={"24px"}
                      src={geneiLogo}
                    />
                    <Text fontWeight={400} fontSize={"14px"}>
                      Wadhwani GenieAI
                    </Text>
                  </Flex>
                )}
              </Text>
              <Text ml={"33px"} width={"100%"}>
                {FormatMessageContent(message.content)}
              </Text>
            </Box>
          ))}
          {isLoading && (
            <Flex justify="center" mt={4}>
              <Spinner size="md" color="#FF8502" />
            </Flex>
          )}
          <div ref={messagesEndRef} />
        </Flex>
        <Box
          position="sticky"
          bottom={0}
          width="100%"
          borderRadius={"15px"}
          bg={{ base: "white", lg: "white" }}
          p={{ base: 2, lg: 4 }}
        >
          <Flex
            border="1px solid #D9D9D9"
            borderRadius="16px"
            h="70px"
            align="center"
            justify="center"
            width={{ base: "100%", sm: "75%", lg: "65%" }}
            mx="auto"
          >
            <Input
              placeholder="How can GenieAI help you today?"
              size={{ base: "sm", sm: "sm" }}
              bg="white"
              pr="4.5rem"
              border="none"
              h="40px"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
              focusBorderColor="transparent"
              backgroundColor="transparent"
              _focus={{ boxShadow: "none" }}
            />

            <Image
              w={{ base: "30px", lg: "30px" }}
              mr={3}
              src={inputValue ? send_active_icon : send_inactive_icon}
              alt="send_icon"
              onClick={handleSendMessage}
              cursor="pointer"
            />
            {/* <Image src={attach} position={"absolute"} right={14} top={5} /> */}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default ChatpagePlaybook;
