// src/components/MainLayout.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Image,
  VStack,
  Text,
  HStack,
  IconButton,
  Collapse,
  Button,
  Link,
  Divider,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import {
  AddIcon,
  MinusIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreateBusinessPlan from "../components/createbusinessplan.js";
import { fetchProjects } from "../services/api";
import start_new from "../assets/start_new.png";
import home_icon from "../assets/home_icon.png";
import wadhwaniImage from "../assets/wadhwani.png";
import profile_icon from "../assets/profile_icon.png";
import bell_icon from "../assets/bell_icon.png";
import { setProjectName, setSectionName } from "../actions/projectActions";
import PlanPage from "../components/PlanPage.js";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb.js";
// BreadCrumb

const SidebarItem = ({ icon, label, children, isOpen, onToggle }) => (
  <Box w="full">
    <HStack
      p={4}
      // bg={isOpen ? "#F7ECD9" : "#FEF8F5"}
      // borderLeft={isOpen ? "6px solid #F68821" : "none"}
      cursor="pointer"
      onClick={onToggle}
      // _hover={{ bg: "#F7ECD9" }}
      justify="space-between"
    >
      <HStack spacing={3}>
        {icon}
        <Text color={isOpen ? "#FF7A59" : "#333333"}>{label}</Text>
      </HStack>
      <IconButton
        icon={
          isOpen ? (
            <MinusIcon color={"#C01F27"} />
          ) : (
            <AddIcon color={"#C01F27"} />
          )
        }
        size="sm"
        variant={""}
        aria-label={isOpen ? "Collapse" : "Expand"}
      />
    </HStack>
    <Collapse in={isOpen}>
      <VStack align="start" pl={4} bg="#FEF8F5" spacing={2} py={2}>
        {children}
      </VStack>
    </Collapse>
  </Box>
);

const BusinessPlanPage = () => {
  const [activeIndex, setActiveIndex] = React.useState(null);

  const [isModalOpen, setModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("input");

  const { instance, accounts } = useMsal();

  const [openIndex, setOpenIndex] = useState(null);
  const [projects, setProjects] = useState([]);
  const dispatch = useDispatch();

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // useEffect(() => {
  //   const fetchChats = async () => {
  //     if (accounts.length > 0) {
  //       try {
  //         const accessTokenRequest = {
  //           scopes: [
  //             "https://webapptest1.onmicrosoft.com/tasks-api/tasks.read",
  //             "https://webapptest1.onmicrosoft.com/tasks-api/tasks.write",
  //           ],
  //           account: accounts[0],
  //         };

  //         const accessTokenResponse = await instance.acquireTokenSilent(
  //           accessTokenRequest
  //         );
  //         const token = accessTokenResponse.accessToken;

  //         const response = await fetch(
  //           "http://localhost:3001/master_coach/chats",
  //           {
  //             method: "GET",
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //               "Content-Type": "application/json",
  //             },
  //           }
  //         );

  //         if (response.ok) {
  //           const data = await response.json();
  //           console.log(data.chats);
  //         } else {
  //           console.error("Failed to fetch chats:", response.statusText);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching chats:", error);
  //       }
  //     }
  //   };

  //   fetchChats();
  // }, [instance, accounts]);

  // useEffect(() => {
  //   const loadProjects = async () => {
  //     try {
  //       const response = await fetchProjects();
  //       setProjects(response.projects);
  //     } catch (error) {
  //       console.error("Error fetching projects:", error);
  //     }
  //   };
  //   loadProjects();
  // }, []);

  const handleContinue = (projectName) => {
    dispatch(setProjectName(projectName));
    dispatch(setSectionName("company_description"));
    localStorage.setItem("projectName", projectName);
    localStorage.setItem("sectionName", "company_description");
    setModalOpen(false);
    // navigate(`/business-plan/company_description`);
  };

  const breadcrumbPaths = [
    { label: "Home", href: "/" },
    { label: "AI Assistants", href: "/assistants" },
    { label: "Business Plan", href: "/business-plan" },
  ];

  return (
    <Flex direction="column" h="100%" bg="white" className="hideScrollbar">
      <Box flex="0 0 auto">
        <Flex mx={{ base: "40px", lg: "50px" }} py={"20px"}>
          <BreadCrumb paths={breadcrumbPaths} />
        </Flex>
      </Box>

      <Box flex="1 1 auto" overflowY="scroll" className="hideScrollbar">
        <PlanPage />
      </Box>
    </Flex>
  );
};

export default BusinessPlanPage;
