// src/reducers/projectReducer.js

// import { SET_QUERY, CLEAR_QUERY } from "../actions/projectActions";

const SET_QUERY = "SET_QUERY";
const CLEAR_QUERY = "CLEAR_QUERY";

const initialState = {
  projectName: localStorage.getItem("projectName") || "",
  sectionName: localStorage.getItem("sectionName") || "summary",
  query: "",
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PROJECT_NAME":
      return {
        ...state,
        projectName: action.payload,
      };
    case "SET_SECTION_NAME":
      return {
        ...state,
        sectionName: action.payload,
      };
    case SET_QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case CLEAR_QUERY:
      return {
        ...state,
        query: "",
      };
    default:
      return state;
  }
};

export default projectReducer;
