import React from 'react'
import PlaybookDefault from '../PlaybookDefault/PlaybookDefault'

const Gtm = () => {
  const breadcrumbPaths = [
    { label: "Home", href: "/" },
    { label: "AI Assistants", href: "/assistants" },
    { label: "Marketing", href: "/assistants/marketing" },
  ];

  return (
    <div>
        <PlaybookDefault
        breadcrumbPaths={breadcrumbPaths}
        fetchHistory={"/marketing/playbook/list"}
        selectChat={"/marketing/playbook"}
        newChatId={"/marketing/playbook"}
        sendMessagee={"/marketing/playbook"}
      />
    </div>
  )
}

export default Gtm