import React from "react";
import hr_icon from "../assets/AI Team/4.png";
import marketing_icon from "../assets/AI Team/1.png";
import sales_icon from "../assets/AI Team/2.png";
import strategy_icon from "../assets/AI Team/3.png";
import {
  Box,
  Grid,
  Heading,
  Text,
  Image,
  VStack,
  Flex,
  Divider,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const Card = ({ title, description, conversations, imageSrc, href }) => (
  <Box
    borderRadius="lg"
    overflow="hidden"
    px={4}
    py={4}
    backgroundColor={"#FEF8F5"}
  >
    <VStack align="start" spacing={3}>
      <Flex gap={"12px"}>
        <Image src={imageSrc} borderRadius="md" boxSize="80px" />
        <Link to={href}>
          <Flex
            flexDirection={"column"}
            justifyContent={"space-between"}
            gap={"8px"}
          >
            <Heading fontSize={"18px"} fontWeight={500} textColor={"#333333"}>
              {title}
            </Heading>
            <Text fontSize="14px" fontWeight={"400"}>
              {description}
            </Text>
          </Flex>
        </Link>
      </Flex>
      <Divider color={"#FCDCBB"} borderColor={"#FCDCBB"} borderWidth={"1px"} />
      <Flex gap={"6px"} fontSize="xs" color="#F2682B">
        {conversations.toLocaleString()}+
        <Text color="gray.500">Conversations</Text>
      </Flex>
    </VStack>
  </Box>
);

const Assistants = () => {
  const cards = [
    {
      title: "Strategy",
      description: "Create a plan of action to achieve specific goals.",
      conversations: 21000,
      imageSrc: strategy_icon,
      href: "/strategy",
    },
    // {
    //   title: 'Product Development',
    //   description: 'Assists to create and improve products to meet customer needs effectively.',
    //   conversations: 10000,
    //   imageSrc: sales_icon,
    //   href:"/product-dev"
    // },
    {
      title: "Sales",
      description:
        "Increase sales Pipeline and revenue with the help of AI sales coach by automating lead generation and sales process.",
      conversations: 25000,
      imageSrc: marketing_icon,
      href: "/agent-container",
    },
    {
      title: "Marketing",
      description:
        "Tired of figuring out marketing on your own? Work together with our marketing coach to take your marketing to next level.",
      conversations: 24000,
      imageSrc: hr_icon,
      href: "/marketing",
    },
    // {
    //   title: 'Finance',
    //   description: 'Use the expertise of our finance coach in various aspects of finance for startups and founders',
    //   conversations: 19000,
    //   imageSrc: marketing_icon,
    //   href:"/finance"
    // },
    {
      title: "HR",
      description:
        "Overcome common people-management challenges with the help of human resources coach.",
      conversations: 9000,
      imageSrc: hr_icon,
      href: "/hr",
    },
    // Add more card data here...
  ];

  return (
    <Grid
      templateColumns={{
        base: "1fr",
        md: "repeat(2, 1fr)",
        lg: "repeat(2, 1fr)",
      }}
      scrollBehavior={"smooth"}
      maxW={{ base: "", lg: "62rem" }}
      marginX={"auto"}
      overflowY={{ base: "auto", lg: "hidden" }}
      gap={6}
      p={{ base: 6, lg: 12 }}
    >
      {cards.map((card, index) => (
        <Card key={index} {...card} />
      ))}
    </Grid>
  );
};

export default Assistants;
